import React, { useEffect, Dispatch } from "react";
import "./App.css";
import {
  createMuiTheme,
  ThemeProvider,
  Box,
  Typography,
} from "@material-ui/core";
import { blue, pink } from "@material-ui/core/colors";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { CalendarComponent } from "./pages/calendar";
import { ListComponent } from "./pages/list";
import { DailyNewsletterComponent } from "./pages/daily-newsletter";

import {
  PostControllerImpl,
  PostController,
} from "./components/application/posts/controller";
import {
  CookieController,
  CookieControllerImpl,
} from "./components/application/cookies/controller";
import {
  UserController,
  UserControllerImpl,
} from "./components/application/users/controller";
import {
  CategoryController,
  CategoryControllerImpl,
} from "./components/application/categories/controller";
import {
  SocialMediaAutoPostController,
  SocialMediaAutoPostControllerImpl,
} from "./components/application/social-media-auto-post/controller";
import { useDispatch } from "react-redux";
import { SundayNewsletterComponent } from "./pages/sunday-newsletter";

async function cookiesLoaded(callback: () => void): Promise<void> {
  const cookieController: CookieController = new CookieControllerImpl();

  if (cookieController.getCookie("cookies_loaded_by_hostname") != "1") {
    window.setTimeout(() => {
      cookiesLoaded(callback);
    }, 500);
  } else {
    callback();
  }
}

async function loadState(
  dispatch: Dispatch<any>,
  firstLoad: boolean
): Promise<void> {
  if (firstLoad) {
    const cookieController: CookieController = new CookieControllerImpl();
    cookieController.setCookie("cookies_loaded_by_hostname", "0");
    cookieController.loadCookies();

    cookiesLoaded(() => {
      const postController: PostController = new PostControllerImpl();
      postController.loadState(dispatch, true);

      const userController: UserController = new UserControllerImpl();
      userController.loadState(dispatch);

      const categoryController: CategoryController = new CategoryControllerImpl();
      categoryController.loadState(dispatch);

      const socialMediaAutoPostController: SocialMediaAutoPostController = new SocialMediaAutoPostControllerImpl();
      socialMediaAutoPostController.loadState(dispatch);
    });
  } else {
    const postController: PostController = new PostControllerImpl();
    postController.loadState(dispatch, false);
  }
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    loadState(dispatch, true);

    setInterval(() => {
      loadState(dispatch, false);
    }, 30 * 1000);

    setInterval(() => {
      loadState(dispatch, true);
    }, 600 * 1000);
  });

  const theme = createMuiTheme({
    palette: {
      primary: blue,
      secondary: pink,
    },
  });

  return (
    <div className="App">
      <Router>
        <ThemeProvider theme={theme}>
          <Box m={2}>
            <Typography variant="h5" component="h1" style={{ fontWeight: 800 }}>
              EditorialPress
            </Typography>
            <Link to="/list">List View</Link> | 
            <Link to="/calendar">Calendar View</Link> | 
            <Link to="/daily-newsletter">Daily Newsletter</Link> | 
            <Link to="/monthly-newsletter">Monthly Newsletter</Link>
            <Box m={1}></Box>
          </Box>

          <Switch>
            <Route path="/list">
              <ListComponent />
            </Route>
            <Route path="/calendar">
              <CalendarComponent />
            </Route>
            <Route path="/daily-newsletter">
              <DailyNewsletterComponent />
            </Route>
            <Route path="/monthly-newsletter">
              <SundayNewsletterComponent />
            </Route>
            <Route path="/">
              <CalendarComponent />
            </Route>
          </Switch>
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
