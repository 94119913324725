import {
  SocialMediaAutoPostActionType,
  SocialMediaAutoPostActionUpdate,
  typePrefix,
} from "./actions";
import { Schema } from "@wordpress/api-fetch";

export interface SocialMediaAutoPostState {
  configuration: any;
  newsletter: {
    daily: {
      subject: string;
    };
  };
}

const initialState: SocialMediaAutoPostState = {
  configuration: {},
  newsletter: {
    daily: {
      subject: "",
    },
  },
};

export function SocialMediaAutoPostReducer(
  state: SocialMediaAutoPostState = initialState,
  action: SocialMediaAutoPostActionType
): SocialMediaAutoPostState {
  // ignore actions from other reducers
  if (!action.type.startsWith(typePrefix)) {
    return state;
  }

  switch (action.type) {
    case SocialMediaAutoPostActionUpdate.prototype.type:
      const payload = (action as SocialMediaAutoPostActionUpdate).payload;
      state = payload;
      break;
    default:
      console.log(
        "Unknown action type '" +
          action.type +
          "' in SocialMediaAutoPostReducer!"
      );
  }

  return state;
}
