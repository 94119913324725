import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { TextField, Box, MenuItem, Select } from "@material-ui/core";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import BlockIcon from "@material-ui/icons/Block";
import LinkIcon from "@material-ui/icons/Link";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../components/application/storage/store";
import { useTranslation } from "react-i18next";
import {
  PostController,
  PostControllerImpl,
} from "../components/application/posts/controller";

import CreateDraftDialog from "../components/presentation/create-draft-dialog/component";
import {
  dateTimeRenderer,
  linkRenderer,
  textFieldRenderer,
} from "../components/presentation/list/component";

function SelectComponent(props: {
  updateValue: any;
  value: any;
  readOnly: boolean;
  items: any;
  withIcon: boolean;
  width?: string;
}) {
  const [open, setOpen] = React.useState(false);

  const onChange = (event: any) => {
    const newValue = (event.target as any).value;
    props.updateValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getStyles = (color: string): any => {
    return {
      color: color,
      float: "left",
      paddingLeft: "10px",
      paddingRight: "10px",
    };
  };

  return (
    <Select
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      value={props.value}
      onChange={onChange}
      readOnly={props.readOnly}
      style={props.width ? { width: props.width } : {}}
    >
      {props.items.map((item: any) => {
        if (!props.withIcon) {
          return <MenuItem value={item.value}>{item.text}</MenuItem>;
        } else {
          if (item.icon) {
            return (
              <MenuItem value={item.value}>
                <Box style={getStyles(item.iconColor)}>{item.icon}</Box>
                {item.text}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem value={item.value}>
                <Box style={getStyles("white")}>
                  <BlockIcon style={{ visibility: "hidden" }} />
                </Box>
                {item.text}
              </MenuItem>
            );
          }
        }
      })}
    </Select>
  );
}

function multiSelectRenderer(options: object) {
  // TODO
  return textFieldRenderer(options);
}

const t = (str: string) => str;

function swapObject(obj: any) {
  var ret: any = {};
  for (var key in obj) {
    ret[obj[key]] = key;
  }
  return ret;
}

export function ListComponent() {
  const { t } = useTranslation();

  const posts = useSelector((state: ApplicationState) => state.posts.all);
  const allAuthors = useSelector(
    (state: ApplicationState) => state.users.authors
  );
  const allCategories = useSelector(
    (state: ApplicationState) => state.categories.all
  );

  const mapQuality: any = {
    good: t("Good"),
    medium: t("Medium"),
    bad: t("Bad"),
    none: t("None"),
    unset: t("Unset"),
  };

  const data: any[] = posts.map((post) => {
    return {
      id: post.id,
      title: post.title,
      author: post.author.displayName,
      status: post.status,
      quality:
        post.plugins?.admin_rate_post?.rating &&
        mapQuality[post.plugins.admin_rate_post.rating]
          ? mapQuality[post.plugins.admin_rate_post.rating]
          : mapQuality["unset"],
      frequency: post.plugins?.admin_reschedule_post?.reschedule_frequency
        ? post.plugins?.admin_reschedule_post?.reschedule_frequency
        : t("unset"),
      categories: Object.keys(post.categories),
      postDate: post.date,
      scaDate: post.plugins?.scheduled_content_actions?.post_date,
      editLink: post.urls.edit,
      viewLink: post.urls.view,
    };
  });

  const columns = [
    {
      name: "id",
      label: t("ID"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={60}>
            {textFieldRenderer({
              readOnly: true,
            })(value, tableMeta, updateValue)}
          </Box>
        ),
      },
    },
    {
      name: "title",
      label: t("Title"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={250}>
            {textFieldRenderer({
              readOnly: true,
            })(value, tableMeta, updateValue)}
          </Box>
        ),
      },
    },
    {
      name: "author",
      label: t("Author"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
          textFieldRenderer({
            readOnly: true,
          })(value, tableMeta, updateValue),
      },
    },
    {
      name: "status",
      label: t("Status"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
          textFieldRenderer({
            readOnly: true,
          })(value, tableMeta, updateValue),
      },
    },
    {
      name: "quality",
      label: t("Quality"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const postId = tableMeta.rowData[0];

          const onChange = (newValue: any) => {
            const postController: PostController = new PostControllerImpl();

            const map = swapObject(mapQuality);
            const quality = newValue && map[newValue] ? map[newValue] : "unset";
            postController.updateQuality(postId, quality);
            updateValue(newValue);
          };

          const items = [
            {
              value: t("Good"),
              iconColor: "#5cb85c",
              icon: <SentimentVerySatisfiedIcon />,
              text: "",
            },
            {
              value: t("Medium"),
              iconColor: "#f0ad4e",
              icon: <SentimentSatisfiedIcon />,
              text: "",
            },
            {
              value: t("Bad"),
              iconColor: "#d9534f",
              icon: <SentimentVeryDissatisfiedIcon />,
              text: "",
            },
            {
              value: t("None"),
              iconColor: "#292b2c",
              icon: <BlockIcon />,
              text: "",
            },
            {
              value: t("Unset"),
              iconColor: "#ffffff",
              icon: <BlockIcon style={{ visibility: "hidden" }} />,
              text: "",
            },
          ];

          return (
            <SelectComponent
              updateValue={onChange}
              value={value}
              readOnly={false}
              items={items}
              withIcon={true}
            />
          );
        },
      },
    },
    {
      name: "frequency",
      label: t("Frequency"),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const postId = tableMeta.rowData[0];

          const onChange = (newValue: any) => {
            const postController: PostController = new PostControllerImpl();

            const frequency: string = newValue ? newValue : "unset";
            postController.updateRescheduleData(postId, frequency);
            updateValue(newValue);
          };

          const items = [
            {
              value: t("1 weeks"),
              text: t("1 weeks"),
            },
            {
              value: t("2 weeks"),
              text: t("2 weeks"),
            },
            {
              value: t("3 weeks"),
              text: t("3 weeks"),
            },
            {
              value: t("1 months"),
              text: t("1 months"),
            },
            {
              value: t("2 months"),
              text: t("2 months"),
            },
            {
              value: t("3 months"),
              text: t("3 months"),
            },
            {
              value: t("6 months"),
              text: t("6 months"),
            },
            {
              value: t("9 months"),
              text: t("9 months"),
            },
            {
              value: t("1 years"),
              text: t("1 years"),
            },
            {
              value: t("2 years"),
              text: t("2 years"),
            },
            {
              value: t("unset"),
              text: "-",
            },
          ];

          return (
            <SelectComponent
              updateValue={onChange}
              value={value}
              readOnly={false}
              items={items}
              withIcon={false}
              width={"80px"}
            />
          );
        },
      },
    },
    {
      name: "categories",
      label: t("Categories"),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
          textFieldRenderer({
            readOnly: true,
          })(value, tableMeta, updateValue),
      },
    },
    {
      name: "postDate",
      label: t("Post Date"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
          dateTimeRenderer({
            readOnly: true,
          })(value, tableMeta, updateValue),
      },
    },
    {
      name: "scaDate",
      label: t("Post Date (SCA)"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const postId = tableMeta.rowData[0];

          const onChange = (newValue: any) => {
            const postController: PostController = new PostControllerImpl();
            postController.updateScaDate(postId, new Date(newValue));
            updateValue(newValue);
          };

          return dateTimeRenderer({
            readOnly: false,
          })(value, tableMeta, onChange);
        },
      },
    },
    {
      name: "editLink",
      label: t("Edit Post"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
          linkRenderer(<LinkIcon />)(value, tableMeta, updateValue),
      },
    },
    {
      name: "viewLink",
      label: t("View Post"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
          linkRenderer(<ExitToAppIcon />)(value, tableMeta, updateValue),
      },
    },
  ];

  const options: any = {
    // "multiselect" | "dropdown" | "checkbox" | "textField" | "custom" | undefined
    filterType: "checkbox",
    selectableRows: false,
  };

  return (
    <>
      <CreateDraftDialog />

      <Box m={2} />

      <div style={{ textAlign: "left" }}>
        <b>Open Bugs:</b>
        <ul>
          <li>
            Clearing Scheduled Content Action Date (={">"} unscheduling) not
            working
          </li>
          <li>Filter by Post Quality not working properly</li>
          <li>Filter by Category: Currently only one selectable</li>
          <li>CSV Export: Currently only all posts (filter are ignored)</li>
        </ul>
      </div>

      <Box m={2} />

      <div style={{ minWidth: "1600px", width: "100%" }}>
        <MUIDataTable
          title={"Posts"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </>
  );
}
