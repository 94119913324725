import {
  EditScreenActionType,
  EditScreenActionUpdate,
  typePrefix,
} from "./actions";
import { Post } from "../../../models/post";
import { DateHelper } from "../../../helpers/date-helper";

export interface EditScreenPostMutableState {
  title: string;
  author: string;
  categories: string[];
  status: string;
  date: Date;
  withTime: boolean;
}

export interface EditScreenPostImmutableState {
  id: number;
  editUrl: string;
  viewUrl: string;
}

interface EditScreenDialogState {
  isOpen: boolean;
  saveButtonStatus: "save" | "saving" | "saved" | "error";
  saveButtonEnabled: boolean;
}

export interface EditScreenState {
  initialState: EditScreenPostMutableState;
  modifiedState: EditScreenPostMutableState;
  immutableState: EditScreenPostImmutableState;
  dialogState: EditScreenDialogState;
}

export function createInitialPostMutableState(): EditScreenPostMutableState {
  return {
    title: "",
    author: "",
    categories: [],
    status: "draft",
    date: new Date(),
    withTime: true,
  };
}

export function createInitialPostMutableStateFromPost(
  post: Post
): EditScreenPostMutableState {
  return {
    title: post.title,
    author: post.author.slug,
    categories: Object.keys(post.categories),
    status: post.status,
    date: DateHelper.getDate(post),
    withTime: post.withTime,
  };
}

export function createInitialPostImmutableState(): EditScreenPostImmutableState {
  return {
    id: 0,
    editUrl: "",
    viewUrl: "",
  };
}

export function createInitialPostImmutableStateFromPost(
  post: Post
): EditScreenPostImmutableState {
  return {
    id: post.id,
    editUrl: post.urls.edit,
    viewUrl: post.urls.view,
  };
}

export function createInitialDialogState(
  isOpen: boolean,
  saveButtonEnabled: boolean
): EditScreenDialogState {
  return {
    isOpen: isOpen,
    saveButtonStatus: "save",
    saveButtonEnabled: saveButtonEnabled,
  };
}

const initialState: EditScreenState = {
  initialState: createInitialPostMutableState(),
  modifiedState: createInitialPostMutableState(),
  immutableState: createInitialPostImmutableState(),
  dialogState: createInitialDialogState(false, false),
};

export function EditScreenReducer(
  state: EditScreenState = initialState,
  action: EditScreenActionType
): EditScreenState {
  // ignore actions from other reducers
  if (!action.type.startsWith(typePrefix)) {
    return state;
  }

  switch (action.type) {
    case EditScreenActionUpdate.prototype.type:
      const payload = (action as EditScreenActionUpdate).payload;
      state = payload;
      break;
    default:
      console.log(
        "Unknown action type '" + action.type + "' in EditScreenReducer!"
      );
  }

  return state;
}
