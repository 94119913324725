import apiFetch, { APIFetchOptions } from "@wordpress/api-fetch";
import axios, { Method, AxiosResponse } from "axios";
import stringifySafe from "json-stringify-safe";
import { UrlHelper } from "../helpers/url-helper";

export interface WordpressFetchService {
  /**
   * Performs a request to the WordPress REST API Returns the result of the WordPress API request.
   * The template parameter is one of the WordPress REST API Schema.
   * The Schema can be imported using following statement: import {Schema} from 'wordpress__api-fetch';
   *
   * @typeparam T          result type, e.g. Schema.Post[]
   * @param     baseUrl    base URL of the WordPress network, e.g. `https://wordpress.airact.com`
   * @param     path       path of the WordPress REST API resource, e.g. '/wp/v2/posts'
   * @param     data       ....
   * @param     headers    ....
   * @returns   {Promise<T>}: promise which will contain the response data
   * @throws    {Error}: error object containing an attribute named 'response' (see https://github.com/axios/axios#response-schema)
   */
  doRequest<T>(
    method: string,
    baseUrl: string,
    path: string,
    data?: any,
    headers?: any
  ): Promise<T>;
}

export class WordpressFetchServiceImpl implements WordpressFetchService {
  async doRequest<T>(
    method: string,
    baseUrl: string,
    path: string,
    data?: any,
    headers?: any
  ): Promise<T> {
    // default api-fetch of WordPress uses the window object inside the fetch handler,
    // so we must register an new one which is using axios for example.
    // defining a custom fetch handler it give us also the availability to define a custom base url.
    apiFetch.setFetchHandler((options: any) => {
      const { path, data, method, headers } = options;

      // example for baseUrl: 'https://wordpress.airact.com'
      // example for path: '/de-DE/wp-json/wp/v2/posts'
      // example for url: https://wordpress.airact.com/de-DE/wp-json/wp/v2/posts'
      const url = baseUrl + path;

      return axios({
        url: url,
        method: method as Method,
        data: data,
        headers: headers,
      });
    });

    // setting path relative to base url
    const options: APIFetchOptions = {
      path: "/wp-json" + UrlHelper.addLeadingSlash(path),
      method: method,
      data: data,
      headers: headers,
    };

    // since the response data contains circular data, we must use stringifySafe
    // to resolve those circles and then we parse it back into an object
    try {
      const response = await apiFetch<AxiosResponse>(options);
      const result = JSON.parse(stringifySafe(response.data));
      return result;
    } catch (err) {
      throw err;
    }
  }
}
