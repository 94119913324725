import {
  CategoriesActionType,
  CategoriesActionUpdate,
  typePrefix,
} from "./actions";
import { Schema } from "@wordpress/api-fetch";

export interface CategoriesState {
  all: Schema.Category[];
}

const initialState: CategoriesState = {
  all: [],
};

export function CategoriesReducer(
  state: CategoriesState = initialState,
  action: CategoriesActionType
): CategoriesState {
  // ignore actions from other reducers
  if (!action.type.startsWith(typePrefix)) {
    return state;
  }

  switch (action.type) {
    case CategoriesActionUpdate.prototype.type:
      const payload = (action as CategoriesActionUpdate).payload;
      state = payload;
      break;
    default:
      console.log(
        "Unknown action type '" + action.type + "' in CategoriesReducer!"
      );
  }

  return state;
}
