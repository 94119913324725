import { Schema } from "@wordpress/api-fetch";
import { Dispatch } from "react";
import { UsersActionFactory } from "./actions";
import { CookieController, CookieControllerImpl } from "../cookies/controller";
import {
  WordpressFetchService,
  WordpressFetchServiceImpl,
} from "../../../providers/wordpress-fetch";

export interface UserController {
  loadState(dispatch: Dispatch<any>): Promise<void>;
}

export class UserControllerImpl implements UserController {
  private async getAuthors(
    baseUrl: string,
    restToken: string
  ): Promise<Schema.User[]> {
    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    try {
      const path =
        "/wp/v2/users?roles=administrator,editor,author,contributor&per_page=100";

      const result = await wordpressFetchService.doRequest<Schema.User[]>(
        "GET",
        baseUrl,
        path,
        null,
        {
          Authorization: "Bearer " + restToken,
        }
      );
      return result;
    } catch (err) {
      const message = "Unexpected error occurred!";
      console.log(message);
      throw err;
    }
  }

  public async loadState(dispatch: Dispatch<any>): Promise<void> {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");
    const restToken = cookieController.getCookie("rest_token_by_hostname");

    const authors = await this.getAuthors(baseUrl, restToken);

    const newState = {
      authors: authors,
    };
    const action = UsersActionFactory.update(newState);
    dispatch(action);
  }
}
