import { UsersActionType, UsersActionUpdate, typePrefix } from "./actions";
import { Schema } from "@wordpress/api-fetch";

export interface UsersState {
  authors: Schema.User[];
}

const initialState: UsersState = {
  authors: [],
};

export function UsersReducer(
  state: UsersState = initialState,
  action: UsersActionType
): UsersState {
  // ignore actions from other reducers
  if (!action.type.startsWith(typePrefix)) {
    return state;
  }

  switch (action.type) {
    case UsersActionUpdate.prototype.type:
      const payload = (action as UsersActionUpdate).payload;
      state = payload;
      break;
    default:
      console.log("Unknown action type '" + action.type + "' in UsersReducer!");
  }

  return state;
}
