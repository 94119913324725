import React, { useState } from "react";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../application/storage/store";
import { Post } from "../../../models/post";
import Calendar from "../calendar/component";
import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
  Input,
  Checkbox,
  ListItemText,
  Button,
  Typography,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  PostController,
  PostControllerImpl,
} from "../../application/posts/controller";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    maxWidth: "300px",
    width: "90%",
  },

  formContainer: {
    //padding: theme.spacing(4),
  },
}));

function filterPost(
  post: Post,
  facebookFilter: string,
  translationFilter: string,
  statuses: string[]
) {
  const facebookReady =
    post.socialMediaStatus.facebookPublish &&
    post.socialMediaStatus.facebookText;

  if (facebookFilter == "onlyIncomplete" && facebookReady) {
    return false;
  } else if (facebookFilter == "onlyComplete" && !facebookReady) {
    return false;
  }

  const translationAll = Object.keys(post.hreflangStatus).length;
  const translationSet = Object.keys(post.hreflangStatus).filter(
    (key) => post.hreflangStatus[key].postId > 0
  ).length;
  const translationReady = translationAll == translationSet;

  if (translationFilter == "onlyIncomplete" && translationReady) {
    return false;
  } else if (translationFilter == "onlyComplete" && !translationReady) {
    return false;
  }

  const postController: PostController = new PostControllerImpl();
  const extendedStatus = postController.getExtendedStatus(post);

  if (statuses.indexOf(extendedStatus) <= -1) {
    return false;
  }

  return true;
}

function CalendarMainComponent(props: { showSettings: boolean }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const styleShown = {
    border: "1px #999999 solid",
    padding: "5px",
    borderRadius: "10px",
  };
  const styleHidden = Object.assign({ display: "none" }, styleShown);

  // -----
  const availableFacebookFilters = {
    all: t("Show All"),
    onlyComplete: t("Show Only Complete"),
    onlyIncomplete: t("Show Only Incomplete"),
  };

  const [facebookFilter, setFacebookFilter] = React.useState("all");
  const handleFacebookFilterChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFacebookFilter(event.target.value as string);
  };

  // -----
  const availableTranslationFilters = {
    all: t("Show All"),
    onlyComplete: t("Show Only Complete"),
    onlyIncomplete: t("Show Only Incomplete"),
  };

  const [translationFilter, setTranslationFilter] = React.useState("all");
  const handleTransationFilterChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setTranslationFilter(event.target.value as string);
  };

  // ------

  const availableStatuses: { [key: string]: string } = {
    all: t("Select All"),
    none: t("Select None"),
    scheduled: t("Scheduled"),
    draft: t("Draft"),
    published: t("Published"),
    rescheduled: t("Rescheduled"),
    pending: t("Pending"),
    "private:": t("Private"),
  };

  const allStatusValues = Object.keys(availableStatuses).filter((key) => {
    return key != "all" && key != "none";
  });

  const [statuses, setStatuses] = React.useState<string[]>(allStatusValues);
  const handleStatusesChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    let selected = event.target.value as string[];

    if (selected.indexOf("all") > -1) {
      selected = allStatusValues;
    } else if (selected.indexOf("none") > -1) {
      selected = [];
    }

    setStatuses(selected);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // ------

  const [from, setFront] = useState(7);
  const [to, setTo] = useState(20);

  const [calendarHeight, setCalendarHeight] = useState(5);
  const handleCalendarHeightChange = (event: React.ChangeEvent<any>) => {
    setCalendarHeight(event.target.value as number);
  };

  // ------

  const posts = useSelector((state: ApplicationState) => state.posts.all);
  const postsFiltered = posts.filter((post) => {
    return filterPost(post, facebookFilter, translationFilter, statuses);
  });

  // ------

  return (
    <div>
      <Box style={props.showSettings ? styleShown : styleHidden}>
        <TextField
          id="calendar-height-input"
          type="number"
          label={t("Calendar Height [1 (small) - 10 (big)]")}
          className={classes.formControl}
          value={calendarHeight}
          onChange={handleCalendarHeightChange}
        />

        <Box m={1}></Box>

        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">
            {t("Facebook Status")}
          </InputLabel>

          <Select
            labelId="facebook-filter-label"
            id="facebook-filter-select"
            value={facebookFilter}
            onChange={handleFacebookFilterChange}
          >
            {Object.entries(availableFacebookFilters).map(([key, value]) => {
              return <MenuItem value={key}>{value}</MenuItem>;
            })}
          </Select>
        </FormControl>

        <Box m={1}></Box>

        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">
            {t("Translation Status")}
          </InputLabel>

          <Select
            labelId="translation-filter-label"
            id="translation-filter-select"
            value={translationFilter}
            onChange={handleTransationFilterChange}
          >
            {Object.entries(availableTranslationFilters).map(([key, value]) => {
              return <MenuItem value={key}>{value}</MenuItem>;
            })}
          </Select>
        </FormControl>

        <Box m={1}></Box>

        <FormControl className={classes.formControl}>
          <InputLabel id="demo-mutiple-checkbox-label">
            {t("Post Status")}
          </InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={statuses}
            onChange={handleStatusesChange}
            input={<Input />}
            renderValue={(selected) =>
              (selected as string[])
                .map((key) => {
                  return availableStatuses[key];
                })
                .join(", ")
            }
            MenuProps={MenuProps}
          >
            {Object.entries(availableStatuses).map(([key, value]) => {
              return (
                <MenuItem key={key} value={key}>
                  <Checkbox checked={statuses.indexOf(key) > -1} />
                  <ListItemText primary={value} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <Box m={1}></Box>

        <Typography variant="h6" component="h3" style={{ fontWeight: 800 }}>
          Colors
        </Typography>

        <Box m={1}></Box>

        <Button
          variant="contained"
          color="secondary"
          style={{
            fontWeight: 600,
            background: "#3399ff",
            marginRight: "10px",
          }}
        >
          {t("News")}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          style={{
            fontWeight: 600,
            background: "#ee77ee",
            marginRight: "10px",
          }}
        >
          {t("Guides")}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          style={{
            fontWeight: 600,
            background: "#ff9900",
            marginRight: "10px",
          }}
        >
          {t("Deals")}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          style={{ fontWeight: 600, background: "#669999" }}
        >
          {t("Other")}
        </Button>

        <Box m={2}></Box>
      </Box>

      <Box m={3}></Box>

      <Calendar
        posts={postsFiltered}
        from={from}
        to={to}
        height={calendarHeight}
      />
    </div>
  );
}

export default CalendarMainComponent;
