import { Post } from "../../../models/post";
import {
  WordpressFetchServiceImpl,
  WordpressFetchService,
} from "../../../providers/wordpress-fetch";
import { ApiResponse } from "../../../models/api-response";
import { Dispatch } from "react";
import { SocialMediaAutoPostActionFactory } from "./actions";
import { CookieController, CookieControllerImpl } from "../cookies/controller";

export interface SocialMediaAutoPostController {
  loadState(dispatch: Dispatch<any>): Promise<void>;
  postNewsletterDailyData(data: any): Promise<any>;
  getNewsletterSundayData(): Promise<any>;
  postNewsletterSundayData(data: any): Promise<any>;
}

export class SocialMediaAutoPostControllerImpl
  implements SocialMediaAutoPostController {
  public async getNewsletterDailyData(baseUrl: string): Promise<any> {
    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    try {
      const path =
        "/editorialpress/v1/social-media-auto-post/newsletter/daily/data" +
        "?apikey=3125FE942564542A1A9B1F574DB0261B97CC03B2558158CBBFFA2AA301FFEECC";

      const result = await wordpressFetchService.doRequest<ApiResponse<Post>>(
        "GET",
        baseUrl,
        path
      );
      if (result.success && result.data !== null) {
        return result.data;
      } else {
        const message = "Unexpected error occurred!";
        console.log(message);
        throw new Error();
      }
    } catch (err) {
      const message = "Unexpected error occurred!";
      console.log(message);
      throw err;
    }
  }

  public async postNewsletterDailyData(data: any): Promise<any> {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");
    const restToken = cookieController.getCookie("rest_token_by_hostname");

    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    try {
      const path =
        "/editorialpress/v1/social-media-auto-post/newsletter/daily/data" +
        "?apikey=3125FE942564542A1A9B1F574DB0261B97CC03B2558158CBBFFA2AA301FFEECC";

      const result = await wordpressFetchService.doRequest<ApiResponse<Post>>(
        "POST",
        baseUrl,
        path,
        data,
        {
          Authorization: "Bearer " + restToken,
        }
      );
      if (result.success && result.data !== null) {
        return result.data;
      } else {
        const message = "Unexpected error occurred!";
        console.log(message);
        throw new Error();
      }
    } catch (err) {
      const message = "Unexpected error occurred!";
      console.log(message);
      throw err;
    }
  }

  public async getNewsletterSundayData(): Promise<any> {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");
    const restToken = cookieController.getCookie("rest_token_by_hostname");

    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    try {
      const path =
        "/editorialpress/v1/social-media-auto-post/newsletter/sunday/data" +
        "?apikey=3125FE942564542A1A9B1F574DB0261B97CC03B2558158CBBFFA2AA301FFEECC";

      const result = await wordpressFetchService.doRequest<ApiResponse<Post>>(
        "GET",
        baseUrl,
        path
      );
      if (result.success && result.data !== null) {
        return result.data;
      } else {
        const message = "Unexpected error occurred!";
        console.log(message);
        throw new Error();
      }
    } catch (err) {
      const message = "Unexpected error occurred!";
      console.log(message);
      throw err;
    }
  }

  public async postNewsletterSundayData(data: any): Promise<any> {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");
    const restToken = cookieController.getCookie("rest_token_by_hostname");

    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    try {
      const path =
        "/editorialpress/v1/social-media-auto-post/newsletter/sunday/data" +
        "?apikey=3125FE942564542A1A9B1F574DB0261B97CC03B2558158CBBFFA2AA301FFEECC";

      const result = await wordpressFetchService.doRequest<ApiResponse<Post>>(
        "POST",
        baseUrl,
        path,
        data,
        {
          Authorization: "Bearer " + restToken,
        }
      );
      if (result.success && result.data !== null) {
        return result.data;
      } else {
        const message = "Unexpected error occurred!";
        console.log(message);
        throw new Error();
      }
    } catch (err) {
      const message = "Unexpected error occurred!";
      console.log(message);
      throw err;
    }
  }

  public async getConfiguration(baseUrl: string): Promise<any> {
    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    try {
      const path =
        "/editorialpress/v1/social-media-auto-post/configuration" +
        "?apikey=3125FE942564542A1A9B1F574DB0261B97CC03B2558158CBBFFA2AA301FFEECC";

      const result = await wordpressFetchService.doRequest<ApiResponse<Post>>(
        "GET",
        baseUrl,
        path
      );
      if (result.success && result.data !== null) {
        return result.data;
      } else {
        const message = "Unexpected error occurred!";
        console.log(message);
        throw new Error();
      }
    } catch (err) {
      const message = "Unexpected error occurred!";
      console.log(message);
      throw err;
    }
  }

  public async loadState(dispatch: Dispatch<any>): Promise<void> {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");
    const restToken = cookieController.getCookie("rest_token_by_hostname");

    const configuration = await this.getConfiguration(baseUrl);
    const newsletterDailyData = await this.getNewsletterDailyData(baseUrl);
    const newsletterDailySubject = newsletterDailyData.subject;

    const additionalState = {
      configuration: configuration,
      newsletter: {
        daily: {
          subject: newsletterDailySubject,
        },
      },
    };

    const action = SocialMediaAutoPostActionFactory.update(additionalState);
    dispatch(action);
  }
}
