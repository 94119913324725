import { PostsReducer, PostsState } from "../posts/reducer";
import { CombinedState, combineReducers, createStore } from "redux";
import { UsersState, UsersReducer } from "../users/reducer";
import { CategoriesReducer, CategoriesState } from "../categories/reducer";
import { EditScreenReducer, EditScreenState } from "../edit-screen/reducer";
import {
  SocialMediaAutoPostReducer,
  SocialMediaAutoPostState,
} from "../social-media-auto-post/reducer";

const combinedReducer = combineReducers({
  posts: PostsReducer,
  categories: CategoriesReducer,
  users: UsersReducer,
  editScreen: EditScreenReducer,
  socialMediaAutoPost: SocialMediaAutoPostReducer,
});

export type ApplicationState = CombinedState<{
  posts: PostsState;
  categories: CategoriesState;
  users: UsersState;
  editScreen: EditScreenState;
  socialMediaAutoPost: SocialMediaAutoPostState;
}>;

export const store = createStore(
  combinedReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);
