import { UsersState } from "./reducer";

export class UsersActionUpdate {
  type!: string;
  payload!: UsersState;
}

// define type names for redux
export const typePrefix = "USERS::";
UsersActionUpdate.prototype.type = typePrefix + "UPDATE";

// aggregate actions
export type UsersActionType = UsersActionUpdate;

export class UsersActionFactory {
  static update(additionState: UsersState): UsersActionUpdate {
    return {
      type: UsersActionUpdate.prototype.type,
      payload: additionState,
    };
  }
}
