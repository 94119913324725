import { Box, TextField } from "@material-ui/core";
import React from "react";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

export function linkRenderer(icon: any) {
  return (value: any, tableMeta: any, updateValue: any) => {
    return (
      <Box style={{ paddingLeft: "10px" }}>
        <a href={value} target="_blank" style={{ color: "#757575" }}>
          {icon}
        </a>
      </Box>
    );
  };
}

export function textFieldRenderer(options: object) {
  let readOnly = false;
  if ((options as any)?.readOnly) {
    readOnly = true;
  }

  return (value: any, tableMeta: any, updateValue: any) => {
    const onChange = (event: any) => {
      const newValue = (event.target as any).value;
      updateValue(newValue);
    };

    return (
      <TextField
        value={value}
        onChange={onChange}
        fullWidth
        InputProps={{
          readOnly: readOnly,
        }}
      />
    );
  };
}

export function dateTimeRenderer(options: object) {
  let readOnly = false;
  if ((options as any)?.readOnly) {
    readOnly = true;
  }

  return (value: any, tableMeta: any, updateValue: any) => {
    const date = value ? Date.parse(value) : null;

    const onChange = (newValue: MaterialUiPickersDate) => {
      if (newValue) {
        updateValue(newValue.toISOString());
      }
    };

    return (
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={"en"}
      >
        <KeyboardDateTimePicker
          margin="normal"
          id="date-picker-dialog"
          format="DD.MM.YYYY HH:mm"
          value={date}
          clearable
          autoOk={true}
          error={value && !date}
          onChange={onChange}
          readOnly={readOnly}
          style={{ minWidth: "200px" }}
        />
      </MuiPickersUtilsProvider>
    );
  };
}
