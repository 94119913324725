import {
  PostsActionType,
  PostsActionLoad,
  PostsActionUpdate,
  typePrefix,
} from "./actions";
import { Post } from "../../../models/post";

export interface PostsState {
  all: Post[];
}

const initialState: PostsState = {
  all: [],
};

export function PostsReducer(
  state: PostsState = initialState,
  action: PostsActionType
): PostsState {
  // ignore actions from other reducers
  if (!action.type.startsWith(typePrefix)) {
    return state;
  }

  switch (action.type) {
    case PostsActionLoad.prototype.type: {
      const payload = (action as PostsActionLoad).payload;
      state = payload;
      break;
    }

    case PostsActionUpdate.prototype.type: {
      const payload = (action as PostsActionUpdate).payload;

      const updatedIds = payload.all.map((post) => post.id);
      state.all = state.all.filter((post) => !updatedIds.includes(post.id));
      state.all = state.all.concat(payload.all);
      state.all.sort((aPost, bPost) => aPost.id - bPost.id);

      break;
    }

    default:
      console.log("Unknown action type '" + action.type + "' in PostsReducer!");
  }

  return state;
}
