import { Post } from "../../../models/post";
import {
  WordpressFetchServiceImpl,
  WordpressFetchService,
} from "../../../providers/wordpress-fetch";
import { ApiResponse } from "../../../models/api-response";
import { CookieController, CookieControllerImpl } from "../cookies/controller";

export interface ReisetopiaNewsletterController {
  postNewsletterSchedule(): Promise<any>;
  getScheduledNewsletters(): Promise<any>;
  deleteScheduledNewsletter(id: string): Promise<any>;
}

export class ReisetopiaNewsletterControllerImpl
  implements ReisetopiaNewsletterController {
  public async postNewsletterSchedule(): Promise<any> {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");
    const restToken = cookieController.getCookie("rest_token_by_hostname");

    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    try {
      const path =
        "/reisetopia-newsletter/v1/campaigns" +
        "?apikey=321EB4A93651AD88DC5D4AE5096B255517D998C0CC2B8A13F41EA28DEAC41A75";

      const result = await wordpressFetchService.doRequest<ApiResponse<Post>>(
        "POST",
        baseUrl,
        path,
        {},
        {
          Authorization: "Bearer " + restToken,
        }
      );
      if (result.success && result.data !== null) {
        return result.data;
      } else {
        const message = "Unexpected error occurred!";
        console.log(message);
        throw new Error();
      }
    } catch (err) {
      const message = "Unexpected error occurred!";
      console.log(message);
      throw err;
    }
  }

  public async getScheduledNewsletters(): Promise<any> {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");
    const restToken = cookieController.getCookie("rest_token_by_hostname");

    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    try {
      const path =
        "/reisetopia-newsletter/v1/campaigns" +
        "?apikey=321EB4A93651AD88DC5D4AE5096B255517D998C0CC2B8A13F41EA28DEAC41A75";

      const result = await wordpressFetchService.doRequest<ApiResponse<Post>>(
        "GET",
        baseUrl,
        path,
        {},
        {
          Authorization: "Bearer " + restToken,
        }
      );
      if (result.success && result.data !== null) {
        return result.data;
      } else {
        const message = "Unexpected error occurred!";
        console.log(message);
        throw new Error();
      }
    } catch (err) {
      const message = "Unexpected error occurred!";
      console.log(message);
      throw err;
    }
  }

  public async deleteScheduledNewsletter(id: string): Promise<any> {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");
    const restToken = cookieController.getCookie("rest_token_by_hostname");

    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    try {
      const path =
        "/reisetopia-newsletter/v1/campaigns/" +
        id +
        "/delete" +
        "?apikey=321EB4A93651AD88DC5D4AE5096B255517D998C0CC2B8A13F41EA28DEAC41A75";

      const result = await wordpressFetchService.doRequest<ApiResponse<Post>>(
        "POST",
        baseUrl,
        path,
        {},
        {
          Authorization: "Bearer " + restToken,
        }
      );
      if (result.success && result.data !== null) {
        return result.data;
      } else {
        const message = "Unexpected error occurred!";
        console.log(message);
        throw new Error();
      }
    } catch (err) {
      const message = "Unexpected error occurred!";
      console.log(message);
      throw err;
    }
  }
}
