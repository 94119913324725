import React, { useEffect } from "react";

import { Calendar, momentLocalizer } from "react-big-calendar";
import { Event as CalendarEvent } from "react-big-calendar";
import CalendarCard from "../calendar-card/component";
import moment from "moment";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

import "./style.css";
import { Post } from "../../../models/post";
import { EditScreenActionFactory } from "../../application/edit-screen/actions";
import { useDispatch } from "react-redux";
import {
  createInitialPostMutableStateFromPost,
  createInitialPostImmutableStateFromPost,
  createInitialDialogState,
} from "../../application/edit-screen/reducer";
import { DateHelper } from "../../../helpers/date-helper";

const DraggableCalendar = withDragAndDrop(Calendar);

export interface PostCalendarEvent extends CalendarEvent {
  allDay?: boolean;
  title?: string;
  start?: Date;
  end?: Date;
  resource?: any;

  postData: Post;
}

function mapPost(post: Post): PostCalendarEvent {
  const date = DateHelper.getDate(post);
  if (!post.withTime) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
  }

  return {
    allDay: false,
    title: post.title,
    start: date,
    end: date,
    resource: null,

    postData: post,
  };
}

function CalendarComponent(props: {
  posts: Post[];
  from: number;
  to: number;
  height: number;
}) {
  moment.locale("en", {
    week: {
      doy: 1,
      dow: 1,
    },
  });

  const localizer = momentLocalizer(moment);
  const dispatch = useDispatch();

  const myEventsList = props.posts.map((post) => {
    return mapPost(post);
  });

  const onEventChanged = (data: any) => {
    const newStart = data.start;
    const post: Post = data.event.postData;

    const updates = { date: newStart };
    const modifiedState = Object.assign(
      createInitialPostMutableStateFromPost(post),
      updates
    );

    const state = {
      initialState: createInitialPostMutableStateFromPost(post),
      modifiedState: modifiedState,
      immutableState: createInitialPostImmutableStateFromPost(post),
      dialogState: createInitialDialogState(true, true),
    };

    const action = EditScreenActionFactory.update(state);
    dispatch(action);
  };

  useEffect(() => {
    var style = document.createElement("style");
    style.innerHTML = `
      .rbc-calendar {
        height: ${3000 * props.height}px !important;
      }
      `;
    document.head.appendChild(style);
  });

  return (
    <DraggableCalendar
      localizer={localizer}
      events={myEventsList}
      startAccessor="start"
      endAccessor="end"
      components={{
        event: CalendarCard,
      }}
      min={new Date(0, 0, 0, props.from, 0, 0)}
      max={new Date(0, 0, 0, props.to, 0, 0)}
      draggableAccessor={() => true}
      resizableAccessor={() => false}
      onEventDrop={onEventChanged}
    />
  );
}

export default CalendarComponent;
