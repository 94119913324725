import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Box } from "@material-ui/core";
import CalendarMain from "../components/presentation/calendar-main/component";
import CreateDraftDialog from "../components/presentation/create-draft-dialog/component";

export function CalendarComponent() {
  const { t } = useTranslation();

  const [showSettings, setShowSettings] = React.useState(false);
  const handleClickShowSettings = (event: React.ChangeEvent<any>) => {
    setShowSettings(!showSettings);
  };

  return (
    <>
      <CreateDraftDialog />

      <Button
        variant="contained"
        color="secondary"
        onClick={handleClickShowSettings}
        style={{ fontWeight: 600, marginLeft: "20px" }}
      >
        {showSettings ? t("Hide Settings") : t("Show Settings")}
      </Button>

      <Box m={3}></Box>

      <CalendarMain showSettings={showSettings} />
    </>
  );
}
