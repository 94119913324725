import React, { CSSProperties } from "react";
import { PostCalendarEvent } from "../calendar/component";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import { Post } from "../../../models/post";
import { green } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";

import UpdateIcon from "@material-ui/icons/Update";
import ScheduleIcon from "@material-ui/icons/Schedule";
import PublicIcon from "@material-ui/icons/Public";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LanguageIcon from "@material-ui/icons/Language";
import LockIcon from "@material-ui/icons/Lock";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import {
  PostController,
  PostControllerImpl,
} from "../../application/posts/controller";
import { EditScreenActionFactory } from "../../application/edit-screen/actions";
import { useDispatch } from "react-redux";
import {
  createInitialPostMutableStateFromPost,
  createInitialPostImmutableStateFromPost,
  createInitialDialogState,
} from "../../application/edit-screen/reducer";
import { DateHelper } from "../../../helpers/date-helper";

function getStatusTextAndIcon(
  post: Post,
  t: TFunction
): { text: string; icon: JSX.Element } {
  const style = {
    display: "table-cell",
    verticalAlign: "middle",
    borderRadius: "50%",
    width: "20px",
  };

  const postController: PostController = new PostControllerImpl();
  const extendedStatus = postController.getExtendedStatus(post);

  if (extendedStatus == "scheduled") {
    return {
      text: t("Scheduled"),
      icon: <ScheduleIcon fontSize="small" style={style} />,
    };
  } else if (extendedStatus == "draft") {
    return {
      text: t("Draft"),
      icon: <AssignmentOutlinedIcon fontSize="small" style={style} />,
    };
  } else if (extendedStatus == "published") {
    return {
      text: t("Published"),
      icon: <PublicIcon fontSize="small" style={style} />,
    };
  } else if (extendedStatus == "rescheduled") {
    return {
      text: t("Rescheduled"),
      icon: <UpdateIcon fontSize="small" style={style} />,
    };
  } else if (extendedStatus == "pending") {
    return {
      text: t("Pending"),
      icon: <VisibilityIcon fontSize="small" style={style} />,
    };
  } else if (extendedStatus == "private") {
    return {
      text: t("Private"),
      icon: <LockIcon fontSize="small" style={style} />,
    };
  } else {
    return {
      text: extendedStatus,
      icon: <HelpOutlineIcon fontSize="small" style={style} />,
    };
  }
}

function CalendarCardComponent(input: object) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const event = (input as any).event as PostCalendarEvent;
  const post: Post = event.postData;

  const title = post.title;

  const date = DateHelper.getDate(post);

  let time;
  let timeColor;
  if (post.withTime) {
    time = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    timeColor = "white";
  } else {
    time = t("No Time");
    timeColor = red[500];
  }

  const statusTextAndIcon = getStatusTextAndIcon(post, t);

  const authorName = post.author.displayName;
  const authorImage = post.author.avatarUrl;

  const sms = post.socialMediaStatus;
  const facebookReady = sms.facebookPublish && sms.facebookText;
  const twitterReady = sms.twitterPublish && sms.twitterText;

  const facebookColor = facebookReady ? green[200] : red[500];
  const twitterColor = twitterReady ? green[200] : red[500];

  const translationAll = Object.keys(post.hreflangStatus).length;
  const translationSet = Object.keys(post.hreflangStatus).filter(
    (key) => post.hreflangStatus[key].postId > 0
  ).length;
  const translationReady = translationAll == translationSet;

  const translationStatus = translationSet + "/" + translationAll;
  const translationColor = translationReady ? green[200] : red[500];

  let cardColor = "#669999";
  const catKeys = Object.keys(post.categories);
  if (catKeys.includes("news") || catKeys.includes("actualites")) {
    cardColor = "#3399ff";
  } else if (catKeys.includes("guides")) {
    cardColor = "#ee77ee";
  } else if (catKeys.includes("deals") || catKeys.includes("bons-plans")) {
    cardColor = "#ff9900";
  }

  const fontStyle: CSSProperties = {
    fontSize: "0.8rem",
    marginTop: "4px",
    fontWeight: 500,
  };
  const bottomFontStyle: CSSProperties = { float: "left", marginRight: "5px" };
  const topFontStyle: CSSProperties = {
    display: "table-cell",
    verticalAlign: "middle",
  };
  const spanStyle: CSSProperties = {
    height: "20px",
    verticalAlign: "middle",
    textAlign: "right",
  };
  const iconMarginStyle: CSSProperties = { marginLeft: "4px" };
  const titleContainerStyle: React.CSSProperties = {
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "4px",
    marginBottom: "4px",
    overflowWrap: "break-word",
    whiteSpace: "normal",
    fontWeight: "bold",
    background: "rgba(255, 255, 255, 0.2)",
    padding: "8px",
  };

  const handleOpen = () => {
    const state = {
      initialState: createInitialPostMutableStateFromPost(post),
      modifiedState: createInitialPostMutableStateFromPost(post),
      immutableState: createInitialPostImmutableStateFromPost(post),
      dialogState: createInitialDialogState(true, false),
    };

    const action = EditScreenActionFactory.update(state);
    dispatch(action);
  };

  return (
    <div
      style={{
        background: cardColor,
        marginTop: "-4px",
        paddingTop: "4px",
        marginBottom: "-4px",
        paddingBottom: "4px",
        borderRadius: "4px",
      }}
      onClick={handleOpen}
    >
      <div
        style={{
          height: "20px",
          display: "table",
          margin: "4px 8px",
          width: "100%",
        }}
      >
        {statusTextAndIcon.icon}

        <div style={Object.assign({}, fontStyle, topFontStyle, spanStyle)}>
          <div style={Object.assign({}, iconMarginStyle)}>
            {statusTextAndIcon.text}
          </div>
        </div>

        <div
          style={Object.assign(
            {
              color: timeColor,
              width: "99%",
              paddingLeft: "0px",
              paddingRight: "12px",
            },
            fontStyle,
            topFontStyle,
            spanStyle
          )}
        >
          {time}
        </div>
      </div>

      {/*
      <div style={{marginTop: '4px'}}>
        <div style={{float: 'right', paddingRight: '8px'}}>{time}</div>
        <div style={{float: 'left', marginLeft: '8px'}}>
          {statusTextAndIcon.icon}
          {statusTextAndIcon.text}
        </div>
      </div>
      */}

      <div style={{ clear: "both" }}></div>

      <div style={Object.assign({}, titleContainerStyle)}>{title}</div>

      <div
        style={{
          height: "20px",
          margin: "4px 8px",
          width: "100%",
          textAlign: "left",
        }}
      >
        <div style={Object.assign({}, bottomFontStyle)}>
          <img
            src={authorImage}
            style={{ borderRadius: "50%", width: "20px" }}
          />
        </div>
        <div style={Object.assign({}, fontStyle, bottomFontStyle, spanStyle)}>
          {authorName}
        </div>
      </div>

      <div
        style={{
          height: "20px",
          margin: "4px 8px",
          width: "100%",
          textAlign: "left",
        }}
      >
        <div style={Object.assign({}, bottomFontStyle)}>
          <FacebookIcon style={{ color: facebookColor }} fontSize="small" />
          <TwitterIcon style={{ color: twitterColor }} fontSize="small" />
        </div>
        <div style={Object.assign({}, bottomFontStyle, spanStyle)}>|</div>
        <div style={{ float: "left", marginRight: "0px" }}>
          <LanguageIcon style={{ color: translationColor }} fontSize="small" />
        </div>
        <div
          style={Object.assign(
            { color: translationColor },
            fontStyle,
            bottomFontStyle,
            spanStyle
          )}
        >
          : {translationStatus}
        </div>
      </div>
    </div>
  );
}

export default CalendarCardComponent;
