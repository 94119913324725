import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { TextField, Box, MenuItem, Select, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../application/storage/store";
import { useTranslation } from "react-i18next";
import {
  dateTimeRenderer,
  linkRenderer,
  textFieldRenderer,
} from "../list/component";
import LinkIcon from "@material-ui/icons/Link";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import moment from "moment";
import { DateHelper } from "../../../helpers/date-helper";
import {
  CookieController,
  CookieControllerImpl,
} from "../../application/cookies/controller";

export function NewsletterListDailyComponent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const configuration = useSelector(
    (state: ApplicationState) => state.socialMediaAutoPost.configuration
  );

  let posts = useSelector((state: ApplicationState) => state.posts.all);

  posts = posts.filter((post) => {
    if (post.status != "publish" && post.status != "future") {
      return false;
    }

    let date: any = DateHelper.getDate(post);
    date = date ? moment(date) : null;

    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController.getCookie("rest_url_by_hostname") || "";
    const numberOfDays = baseUrl.includes("suitespot.fr") ? 2 : 1;

    const start = moment()
      .tz("Europe/Berlin")
      .subtract(numberOfDays, "days")
      .set({ hour: 18, minute: 5, second: 0 });
    const end = moment()
      .tz("Europe/Berlin")
      .set({ hour: 18, minute: 5, second: 0 });

    return date && date.isSameOrAfter(start) && date.isSameOrBefore(end);
  });

  posts.sort((post1, post2) => {
    const prio1 =
      post1.plugins?.social_media_auto_post.newsletter.priority.value;
    const prio2 =
      post2.plugins?.social_media_auto_post.newsletter.priority.value;

    if (!prio1 && prio2) {
      return +1;
    }
    if (!prio2 && prio1) {
      return -1;
    }

    if (prio1 && prio2) {
      if (prio1 != prio2) {
        return prio1 - prio2;
      }
    }

    let date1: any = DateHelper.getDate(post1);
    date1 = date1 ? moment(date1) : null;

    let date2: any = DateHelper.getDate(post2);
    date2 = date2 ? moment(date2) : null;

    if (!date1 && date2) {
      return +1;
    }
    if (!date2 && date1) {
      return -1;
    }

    if (date1 && date2) {
      const diff = moment.duration(date2.diff(date1));
      return diff.asSeconds();
    }

    return 0;
  });

  const data: any[] = posts.map((post, index) => {
    const priority =
      post.plugins?.social_media_auto_post.newsletter.priority.value;

    let size = "small";
    if (index < 1) {
      size = "large";
    } else if (index < 4) {
      size = "medium";
    }

    const title =
      post.plugins?.social_media_auto_post.newsletter.title.value[size];
    const content =
      post.plugins?.social_media_auto_post.newsletter.content.value[size];

    const newsletterConfiguration = configuration.channels.filter(
      (channel: any) => channel.id == "newsletter"
    )[0].validation;

    function getLengthInfo(attr: string, size: string, currentSize: string) {
      const length =
        post.plugins?.social_media_auto_post.newsletter?.[attr]?.value?.[size]
          ?.length || 0;

      let style;

      if (configuration) {
        if (
          length < newsletterConfiguration?.[attr]?.minimum_char_count[size] ||
          length > newsletterConfiguration?.[attr]?.maximum_char_count[size]
        ) {
          style = "color: red;";
        } else {
          style = "color: green;";
        }
      }

      if (size == currentSize) {
        style = style + " font-size: 1.3em; font-weight: bold;";
      }

      return { length: length, style: style };
    }

    const titleSmallInfo = getLengthInfo("title", "small", size);
    const titleMediumInfo = getLengthInfo("title", "medium", size);
    const titleLargeInfo = getLengthInfo("title", "large", size);

    const contentSmallInfo = getLengthInfo("content", "small", size);
    const contentMediumInfo = getLengthInfo("content", "medium", size);
    const contentLargeInfo = getLengthInfo("content", "large", size);

    const date: any = DateHelper.getDate(post);

    return {
      id: post.id,
      title: title,
      titleLength:
        '<div><span style="' +
        titleLargeInfo.style +
        '">' +
        titleLargeInfo.length +
        '</span>, <span style="' +
        titleMediumInfo.style +
        '">' +
        titleMediumInfo.length +
        '</span>, <span style="' +
        titleSmallInfo.style +
        '">' +
        titleSmallInfo.length +
        "</span></div>",
      content: content,
      contentLength:
        '<div><span style="' +
        contentLargeInfo.style +
        '">' +
        contentLargeInfo.length +
        '</span>, <span style="' +
        contentMediumInfo.style +
        '">' +
        contentMediumInfo.length +
        '</span>, <span style="' +
        contentSmallInfo.style +
        '">' +
        contentSmallInfo.length +
        "</span></div>",
      author: post.author.displayName,
      status: post.status,
      categories: Object.keys(post.categories),
      postDate: date,
      priority: priority,
      editLink: post.urls.edit,
      viewLink: post.urls.view,
    };
  });

  const columns = [
    {
      name: "editLink",
      label: t("Edit Post"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
          linkRenderer(<LinkIcon />)(value, tableMeta, updateValue),
      },
    },
    {
      name: "viewLink",
      label: t("View Post"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
          linkRenderer(<ExitToAppIcon />)(value, tableMeta, updateValue),
      },
    },
    {
      name: "priority",
      label: t("Priority"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={60}>
            {textFieldRenderer({
              readOnly: true,
            })(value, tableMeta, updateValue)}
          </Box>
        ),
      },
    },
    {
      name: "title",
      label: t("Title"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={450}>
            {textFieldRenderer({
              readOnly: true,
            })(value, tableMeta, updateValue)}
          </Box>
        ),
      },
    },
    {
      name: "titleLength",
      label: t("Title Length"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={100}>
            <div dangerouslySetInnerHTML={{ __html: value }}></div>
          </Box>
        ),
      },
    },
    {
      name: "content",
      label: t("Content"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={450}>
            {textFieldRenderer({
              readOnly: true,
            })(value, tableMeta, updateValue)}
          </Box>
        ),
      },
    },
    {
      name: "contentLength",
      label: t("Content Length"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={100}>
            <div dangerouslySetInnerHTML={{ __html: value }}></div>
          </Box>
        ),
      },
    },
    {
      name: "postDate",
      label: t("Post Date"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
          dateTimeRenderer({
            readOnly: true,
          })(value, tableMeta, updateValue),
      },
    },
    {
      name: "author",
      label: t("Author"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={250}>
            {textFieldRenderer({
              readOnly: true,
            })(value, tableMeta, updateValue)}
          </Box>
        ),
      },
    },
    {
      name: "status",
      label: t("Status"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={250}>
            {textFieldRenderer({
              readOnly: true,
            })(value, tableMeta, updateValue)}
          </Box>
        ),
      },
    },
    {
      name: "id",
      label: t("ID"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={60}>
            {textFieldRenderer({
              readOnly: true,
            })(value, tableMeta, updateValue)}
          </Box>
        ),
      },
    },
  ];

  const options: any = {
    // "multiselect" | "dropdown" | "checkbox" | "textField" | "custom" | undefined
    filterType: "checkbox",
    filter: false,
    download: false,
    print: false,
    rowsPerPage: 500,
    rowsPerPageOptions: [500],
    selectableRows: "none",
    sort: false,
  };

  return (
    <div style={{ minWidth: "2600px", margin: "0 auto" }}>
      <MUIDataTable
        title={"Posts"}
        data={data}
        columns={columns}
        options={options}
      />
    </div>
  );
}
