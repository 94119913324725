import moment from "moment-timezone";
import { Post } from "../models/post";

export class DateHelper {
  static getDate(post: Post): Date {
    let date = post.date;
    if (post.plugins?.scheduled_content_actions?.post_date) {
      date = post.plugins.scheduled_content_actions.post_date;
    }

    return new Date(moment.tz(date, "Europe/Berlin").format());
  }
}
