import { SocialMediaAutoPostState } from "./reducer";

export class SocialMediaAutoPostActionUpdate {
  type!: string;
  payload!: SocialMediaAutoPostState;
}

// define type names for redux
export const typePrefix = "SOCIAL_MEDIA_AUTO_POST::";
SocialMediaAutoPostActionUpdate.prototype.type = typePrefix + "UPDATE";

// aggregate actions
export type SocialMediaAutoPostActionType = SocialMediaAutoPostActionUpdate;

export class SocialMediaAutoPostActionFactory {
  static update(
    additionState: SocialMediaAutoPostState
  ): SocialMediaAutoPostActionUpdate {
    return {
      type: SocialMediaAutoPostActionUpdate.prototype.type,
      payload: additionState,
    };
  }
}
