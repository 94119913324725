import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { textFieldRenderer } from "../list/component";

export function SundayNewsletterPostList(props: {
  title: string;
  state: any;
  setState: any;
}) {
  const { t } = useTranslation();

  const columns = [
    {
      name: "id",
      label: t("#"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={20}>
            {textFieldRenderer({
              readOnly: true,
            })(value, tableMeta, updateValue)}
          </Box>
        ),
      },
    },
    {
      name: "postId",
      label: t("Post Id"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const id = tableMeta.rowData[0];

          const onChange = (newValue: any) => {
            const newState = props.state.map((row: any) => {
              if (row.id == id) {
                return Object.assign(row, { postId: newValue });
              } else {
                return row;
              }
            });

            props.setState(newState);
            updateValue(newValue);
          };

          return (
            <Box width={70}>
              {textFieldRenderer({
                readOnly: false,
              })(value, tableMeta, onChange)}
            </Box>
          );
        },
      },
    },
    {
      name: "title",
      label: t("Title"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const id = tableMeta.rowData[0];

          const onChange = (newValue: any) => {
            const newState = props.state.map((row: any) => {
              if (row.id == id) {
                return Object.assign(row, { title: newValue });
              } else {
                return row;
              }
            });

            props.setState(newState);
            updateValue(newValue);
          };

          return (
            <Box width={600}>
              {textFieldRenderer({
                readOnly: false,
              })(value, tableMeta, onChange)}
            </Box>
          );
        },
      },
    },
  ];

  const options: any = {
    // "multiselect" | "dropdown" | "checkbox" | "textField" | "custom" | undefined
    filterType: "checkbox",
    filter: false,
    download: false,
    print: false,
    rowsPerPage: 500,
    rowsPerPageOptions: [500],
    selectableRows: "none",
    sort: false,
  };

  return (
    <div style={{ minWidth: "900px", margin: "0 auto" }}>
      <MUIDataTable
        title={props.title}
        data={props.state}
        columns={columns}
        options={options}
      />
    </div>
  );
}
