import { EditScreenState } from "./reducer";

export class EditScreenActionUpdate {
  type!: string;
  payload!: EditScreenState;
}

// define type names for redux
export const typePrefix = "EDIT_SCREEN::";
EditScreenActionUpdate.prototype.type = typePrefix + "UPDATE";

// aggregate actions
export type EditScreenActionType = EditScreenActionUpdate;

export class EditScreenActionFactory {
  static update(additionState: EditScreenState): EditScreenActionUpdate {
    return {
      type: EditScreenActionUpdate.prototype.type,
      payload: additionState,
    };
  }
}
