import {
  WordpressFetchServiceImpl,
  WordpressFetchService,
} from "../../../providers/wordpress-fetch";
import { CategoriesActionFactory } from "./actions";
import { Dispatch } from "react";
import { Schema } from "@wordpress/api-fetch";
import { CookieController, CookieControllerImpl } from "../cookies/controller";

export interface CategoryController {
  loadState(dispatch: Dispatch<any>): Promise<void>;
}

export class CategoryControllerImpl implements CategoryController {
  private async getCategories(baseUrl: string): Promise<Schema.Category[]> {
    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    try {
      const path = "/wp/v2/categories?per_page=100";

      const result = await wordpressFetchService.doRequest<Schema.Category[]>(
        "GET",
        baseUrl,
        path
      );
      return result;
    } catch (err) {
      const message = "Unexpected error occurred!";
      console.log(message);
      throw err;
    }
  }

  public async loadState(dispatch: Dispatch<any>): Promise<void> {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");

    const categories = await this.getCategories(baseUrl);

    const newState = {
      all: categories,
    };
    const action = CategoriesActionFactory.update(newState);
    dispatch(action);
  }
}
