import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  MenuItem,
  Select,
  Button,
  TextareaAutosize,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../components/application/storage/store";
import { useTranslation } from "react-i18next";
import {
  SocialMediaAutoPostController,
  SocialMediaAutoPostControllerImpl,
} from "../components/application/social-media-auto-post/controller";
import { SocialMediaAutoPostActionFactory } from "../components/application/social-media-auto-post/actions";
import { NewsletterListSundayComponent } from "../components/presentation/newsletter-list-sunday/component";
import { Editor } from "@tinymce/tinymce-react";
import {
  CookieController,
  CookieControllerImpl,
} from "../components/application/cookies/controller";
import { SundayNewsletterPostList } from "../components/presentation/sunday-newsletter-post-list/component";

export function SundayNewsletterComponent() {
  const tinyApiKey = "b261m2mboc7vzwcvklqkteomeyk0gsf5id94sm9gljkr5uhf";

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [subject, setSubject] = useState("");
  const handleSubjectChange = (event: React.ChangeEvent<any>) => {
    setSubject(event.target.value);
  };

  const [topArticleCount, setTopArticleCount] = useState("");
  const handleTopArticleCountChange = (event: React.ChangeEvent<any>) => {
    setTopArticleCount(event.target.value);
  };

  const [topArticleCountMobile, setTopArticleCountMobile] = useState("");
  const handleTopArticleCountMobileChange = (event: React.ChangeEvent<any>) => {
    setTopArticleCountMobile(event.target.value);
  };

  const [introHeader, setIntroHeader] = useState("");
  const handleIntroHeaderChange = (event: React.ChangeEvent<any>) => {
    setIntroHeader(event.target.value);
  };

  const [introImageUrl, setIntroImageUrl] = useState("");
  const handleIntroImageUrlChange = (event: React.ChangeEvent<any>) => {
    setIntroImageUrl(event.target.value);
  };

  const [introText, setIntroText] = useState("");
  const handleIntroTextChange = (event: React.ChangeEvent<any>) => {
    setIntroText(event.target.value);
  };

  const [commentHeader, setCommentHeader] = useState("");
  const handleCommentHeaderChange = (event: React.ChangeEvent<any>) => {
    setCommentHeader(event.target.value);
  };

  const [commentPostId, setCommentPostId] = useState("");
  const handleCommentPostIdChange = (event: React.ChangeEvent<any>) => {
    setCommentPostId(event.target.value);
  };

  const [commentText, setCommentText] = useState("");
  const handleCommentTextChange = (event: React.ChangeEvent<any>) => {
    setCommentText(event.target.value);
  };

  const [standbyHeader, setStandbyHeader] = useState("");
  const handleStandbyHeaderChange = (event: React.ChangeEvent<any>) => {
    setStandbyHeader(event.target.value);
  };

  const [standbyImageUrl, setStandbyImageUrl] = useState("");
  const handleStandbyImageUrlChange = (event: React.ChangeEvent<any>) => {
    setStandbyImageUrl(event.target.value);
  };

  const [standbyText, setStandbyText] = useState("");
  const handleStandbyTextChange = (event: React.ChangeEvent<any>) => {
    setStandbyText(event.target.value);
  };

  const [qualityArticleHeader, setQualityArticleHeader] = useState("");
  const handleQualityArticleHeaderChange = (event: React.ChangeEvent<any>) => {
    setQualityArticleHeader(event.target.value);
  };

  const [qualityArticlePostId, setQualityArticlePostId] = useState("");
  const handleQualityArticlePostIdChange = (event: React.ChangeEvent<any>) => {
    setQualityArticlePostId(event.target.value);
  };

  const [qualityArticleText, setQualityArticleText] = useState("");
  const handleQualityArticleTextChange = (event: React.ChangeEvent<any>) => {
    setQualityArticleText(event.target.value);
  };

  const [additionalText, setAdditionalText] = useState("");
  const handleAdditionalTextChange = (event: React.ChangeEvent<any>) => {
    setAdditionalText(event.target.value);
  };

  const [nextWeekText, setNextWeekText] = useState("");
  const handleNextWeekTextChange = (event: React.ChangeEvent<any>) => {
    setNextWeekText(event.target.value);
  };

  const initialStandbyArticles = [1, 2, 3, 4, 5].map((id: any) => {
    return {
      id: id,
      postId: "",
      title: "",
    };
  });
  const [standbyArticles, setStandbyArticles] = useState(
    initialStandbyArticles.concat([])
  );

  const initialHotelDeals = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((id: any) => {
    return {
      id: id,
      postId: "",
      title: "",
    };
  });
  const [hotelDeals, setHotelDeals] = useState(initialHotelDeals.concat([]));

  const [customPostOrder, setCustomPostOrder] = useState({});

  const objectToState = (data: any) => {
    setSubject(data.subject);
    setTopArticleCount(data.topArticleCount);
    setTopArticleCountMobile(data.topArticleCountMobile);
    setIntroHeader(data.intro.header);
    setIntroImageUrl(data.intro.imageUrl);
    setIntroText(data.intro.text);
    setCommentHeader(data.comment.header);
    setCommentPostId(data.comment.postId);
    setCommentText(data.comment.text);
    setStandbyHeader(data.standby.header);
    setStandbyImageUrl(data.standby.imageUrl);
    setStandbyText(data.standby.text);
    setQualityArticleHeader(data.qualityArticle.header);
    setQualityArticlePostId(data.qualityArticle.postId);
    setQualityArticleText(data.qualityArticle.text);
    setAdditionalText(data.additionalSection.text);
    setNextWeekText(data.nextWeek.text);
    setHotelDeals(data.hotelDeals || initialHotelDeals.concat([]));
    setStandbyArticles(
      data.standbyArticles || initialStandbyArticles.concat([])
    );
    setCustomPostOrder(data.customPostOrder);
  };

  const stateToObject = () => {
    const data = {
      subject: subject,
      topArticleCount: topArticleCount,
      topArticleCountMobile: topArticleCountMobile,
      intro: {
        header: introHeader,
        imageUrl: introImageUrl,
        text: introText,
      },
      comment: {
        header: commentHeader,
        postId: commentPostId,
        text: commentText,
      },
      standby: {
        header: standbyHeader,
        imageUrl: standbyImageUrl,
        text: standbyText,
      },
      qualityArticle: {
        header: qualityArticleHeader,
        postId: qualityArticlePostId,
        text: qualityArticleText,
      },
      additionalSection: {
        text: additionalText,
      },
      nextWeek: {
        text: nextWeekText,
      },
      hotelDeals: hotelDeals,
      standbyArticles: standbyArticles,
      customPostOrder: customPostOrder,
    };

    return data;
  };

  useEffect(() => {
    const socialMediaAutoPostController: SocialMediaAutoPostController = new SocialMediaAutoPostControllerImpl();
    const promise = socialMediaAutoPostController.getNewsletterSundayData();
    promise
      .then((data) => {
        objectToState(data);
        alert("Loaded data!");
      })
      .catch(() => {
        alert("Error while loading data! Please refresh!");
      });
  }, []);

  const handleSave = (callback: any) => {
    const result = window.confirm(
      t(
        "All settings current changes by other persons will be overwritten. Are you sure?"
      )
    );
    if (result != true) {
      return;
    }

    const data = stateToObject();

    const socialMediaAutoPostController: SocialMediaAutoPostController = new SocialMediaAutoPostControllerImpl();
    const promise = socialMediaAutoPostController.postNewsletterSundayData(
      data
    );
    promise
      .then((data) => {
        alert("Saved data!");
        if (callback) {
          callback();
        }
      })
      .catch(() => {
        alert("Error while saving data! Please try again!");
      });
  };

  const handlePreview = () => {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");

    window.open(
      baseUrl +
        "/wp-json/editorialpress/v1/social-media-auto-post/newsletter/sunday/preview"
    );
  };

  const handleReset = () => {
    const result = window.confirm(
      t("All settings will be resetted. Are you sure?")
    );
    if (result != true) {
      return;
    }

    const data = {
      subject: "",
      topArticleCount: 25,
      topArticleCountMobile: 12,
      intro: {
        header: "",
        imageUrl: "",
        text: "",
      },
      comment: {
        header: "",
        postId: "",
        text: "",
      },
      standby: {
        header: "",
        imageUrl: "",
        text: "",
      },
      qualityArticle: {
        header: "",
        postId: "",
        text: "",
      },
      additionalSection: {
        text: "",
      },
      nextWeek: {
        text: "",
      },
      hotelDeals: initialHotelDeals,
      standbyArticles: initialStandbyArticles,
      customPostOrder: {},
    };

    const socialMediaAutoPostController: SocialMediaAutoPostController = new SocialMediaAutoPostControllerImpl();
    const promise = socialMediaAutoPostController.postNewsletterSundayData(
      data
    );
    promise
      .then((data) => {
        const socialMediaAutoPostController: SocialMediaAutoPostController = new SocialMediaAutoPostControllerImpl();
        const promise = socialMediaAutoPostController.getNewsletterSundayData();
        promise
          .then((data) => {
            objectToState(data);
            alert("Resetted data!");
          })
          .catch(() => {
            alert("Error while resetting! Please try again!");
          });
      })
      .catch(() => {
        alert("Error while resetting! Please try again!");
      });
  };

  const handleRefresh = () => {
    const result = window.confirm(
      t(
        "Your local changes (in case there are any) will be discarded while refreshing this page. Are you sure you want to refresh the page?"
      )
    );
    if (result != true) {
      return;
    }

    window.location.reload();
  };

  const toolbar = () => {
    return (
      <Box
        m={4}
        style={{ width: "600px", padding: "30px", margin: "10px auto" }}
      >
        <Box m={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleSave(null);
            }}
            style={{ fontWeight: 600 }}
          >
            {t("Save")}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleSave(handlePreview);
            }}
            style={{ fontWeight: 600, marginLeft: "20px" }}
          >
            {t("Save & Preview")}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={handleRefresh}
            style={{ fontWeight: 600, marginLeft: "20px" }}
          >
            {t("Refresh")}
          </Button>

          <Button
            variant="contained"
            color="default"
            onClick={handleReset}
            style={{
              fontWeight: 600,
              marginLeft: "20px",
              background: "#CA0B00",
              color: "white",
            }}
          >
            {t("Reset")}
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {toolbar()}

      <Box m={4} />

      <Box
        m={4}
        style={{
          background: "#EBE4E0",
          width: "960px",
          padding: "30px",
          margin: "10px auto",
        }}
      >
        <h2>{t("General")}</h2>

        <Box m={3}>
          <TextField
            id="standard-basic"
            label={t("Subject")}
            value={subject}
            onChange={handleSubjectChange}
            error={!subject}
            style={{ width: "860px" }}
          />
        </Box>

        <Box m={3}>
          <TextField
            id="standard-basic"
            label={t("Top Article Count (Desktop)")}
            value={topArticleCount}
            onChange={handleTopArticleCountChange}
            error={!topArticleCount}
            style={{ width: "860px" }}
          />
        </Box>

        <Box m={3}>
          <TextField
            id="standard-basic"
            label={t("Top Article Count (Mobile)")}
            value={topArticleCountMobile}
            onChange={handleTopArticleCountMobileChange}
            error={!topArticleCountMobile}
            style={{ width: "860px" }}
          />
        </Box>
      </Box>

      <Box
        m={4}
        style={{
          background: "#EBE4E0",
          width: "960px",
          padding: "30px",
          margin: "10px auto",
        }}
      >
        <h2>{t("Intro")}</h2>

        <Box m={3}>
          <TextField
            id="standard-basic"
            label={t("Intro Header")}
            value={introHeader}
            onChange={handleIntroHeaderChange}
            error={!introHeader}
            style={{ width: "860px" }}
          />
        </Box>

        <Box m={3}>
          <TextField
            id="standard-basic"
            label={t("Intro Image URL")}
            value={introImageUrl}
            onChange={handleIntroImageUrlChange}
            error={!introImageUrl}
            style={{ width: "860px" }}
          />
        </Box>

        <Box style={{ width: "860px", margin: "20px auto" }}>
          <Box style={{ textAlign: "left", width: "860px", margin: "0 auto" }}>
            {t("Intro Text")}:
          </Box>

          <Editor
            apiKey={tinyApiKey}
            initialValue={introText || ""}
            init={{
              height: 500,
              width: 860,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
            }}
            onEditorChange={setIntroText}
          />
        </Box>
      </Box>

      <Box
        m={4}
        style={{
          background: "#EBE4E0",
          width: "960px",
          padding: "30px",
          margin: "10px auto",
        }}
      >
        <h2>{t("Standby")}</h2>

        <Box m={3}>
          <TextField
            id="standard-basic"
            label={t("Standby Header")}
            value={standbyHeader}
            onChange={handleStandbyHeaderChange}
            error={!standbyHeader}
            style={{ width: "860px" }}
          />
        </Box>

        <Box m={3}>
          <TextField
            id="standard-basic"
            label={t("Standby Image URL")}
            value={standbyImageUrl}
            onChange={handleStandbyImageUrlChange}
            error={!standbyImageUrl}
            style={{ width: "860px" }}
          />
        </Box>

        <Box style={{ width: "860px", margin: "20px auto" }}>
          <Box style={{ textAlign: "left", width: "860px", margin: "0 auto" }}>
            {t("Standby Text")}:
          </Box>

          <Editor
            apiKey={tinyApiKey}
            initialValue={standbyText || ""}
            init={{
              height: 500,
              width: 860,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
            }}
            onEditorChange={setStandbyText}
          />
        </Box>

        <SundayNewsletterPostList
          title="Posts"
          state={standbyArticles}
          setState={setStandbyArticles}
        />
      </Box>

      <Box
        m={4}
        style={{
          background: "#EBE4E0",
          width: "960px",
          padding: "30px",
          margin: "10px auto",
        }}
      >
        <h2>{t("Comment")}</h2>

        <Box m={3}>
          <TextField
            id="standard-basic"
            label={t("Comment Header")}
            value={commentHeader}
            onChange={handleCommentHeaderChange}
            error={!commentHeader}
            style={{ width: "860px" }}
          />
        </Box>

        <Box m={3}>
          <TextField
            id="standard-basic"
            label={t("Comment Post ID")}
            value={commentPostId}
            onChange={handleCommentPostIdChange}
            error={!commentPostId}
            style={{ width: "860px" }}
          />
        </Box>

        <Box style={{ width: "860px", margin: "20px auto" }}>
          <Box style={{ textAlign: "left", width: "860px", margin: "0 auto" }}>
            {t("Comment Text")}:
          </Box>

          <Editor
            apiKey={tinyApiKey}
            initialValue={commentText || ""}
            init={{
              height: 500,
              width: 860,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
            }}
            onEditorChange={setCommentText}
          />
        </Box>
      </Box>

      <Box
        m={4}
        style={{
          background: "#EBE4E0",
          width: "960px",
          padding: "30px",
          margin: "10px auto",
        }}
      >
        <h2>{t("Hotel Deals")}</h2>

        <SundayNewsletterPostList
          title="Posts"
          state={hotelDeals}
          setState={setHotelDeals}
        />
      </Box>

      <Box
        m={4}
        style={{
          background: "#EBE4E0",
          width: "960px",
          padding: "30px",
          margin: "10px auto",
        }}
      >
        <h2>{t("Quality Article")}</h2>

        <Box m={3}>
          <TextField
            id="standard-basic"
            label={t("Quality Article Header")}
            value={qualityArticleHeader}
            onChange={handleQualityArticleHeaderChange}
            error={!qualityArticleHeader}
            style={{ width: "860px" }}
          />
        </Box>

        <Box m={3}>
          <TextField
            id="standard-basic"
            label={t("Quality Article Post ID")}
            value={qualityArticlePostId}
            onChange={handleQualityArticlePostIdChange}
            error={!qualityArticlePostId}
            style={{ width: "860px" }}
          />
        </Box>

        <Box style={{ width: "860px", margin: "20px auto" }}>
          <Box style={{ textAlign: "left", width: "860px", margin: "0 auto" }}>
            {t("Quality Article Text")}:
          </Box>

          <Editor
            apiKey={tinyApiKey}
            initialValue={qualityArticleText || ""}
            init={{
              height: 500,
              width: 860,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
            }}
            onEditorChange={setQualityArticleText}
          />
        </Box>
      </Box>

      <Box
        m={4}
        style={{
          background: "#EBE4E0",
          width: "960px",
          padding: "30px",
          margin: "10px auto",
        }}
      >
        <h2>{t("Additional Content")}</h2>

        <Box style={{ width: "860px", margin: "20px auto" }}>
          <Box style={{ textAlign: "left", width: "860px", margin: "0 auto" }}>
            {t("Additional Content Text")}:
          </Box>

          <Editor
            apiKey={tinyApiKey}
            initialValue={additionalText || ""}
            init={{
              height: 500,
              width: 860,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
            }}
            onEditorChange={setAdditionalText}
          />
        </Box>
      </Box>

      <Box
        m={4}
        style={{
          background: "#EBE4E0",
          width: "960px",
          padding: "30px",
          margin: "10px auto",
        }}
      >
        <h2>{t("Preview Next Month")}</h2>

        <Box style={{ width: "860px", margin: "20px auto" }}>
          <Box style={{ textAlign: "left", width: "860px", margin: "0 auto" }}>
            {t("Preview Next Month Text")}:
          </Box>

          <Editor
            apiKey={tinyApiKey}
            initialValue={nextWeekText || ""}
            init={{
              height: 500,
              width: 860,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
            }}
            onEditorChange={setNextWeekText}
          />
        </Box>
      </Box>

      <Box m={4} />

      <NewsletterListSundayComponent
        customOrder={customPostOrder}
        setCustomOrder={setCustomPostOrder}
      />

      <Box m={4} />

      {toolbar()}
    </>
  );
}
