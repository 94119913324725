import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TextField, Box, MenuItem, Select, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../application/storage/store";
import { useTranslation } from "react-i18next";
import {
  dateTimeRenderer,
  linkRenderer,
  textFieldRenderer,
} from "../list/component";
import LinkIcon from "@material-ui/icons/Link";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PanToolOutlinedIcon from "@material-ui/icons/PanToolOutlined";
import moment from "moment-timezone";
import { DateHelper } from "../../../helpers/date-helper";
import { Post } from "../../../models/post";

export function NewsletterListSundayComponent(props: {
  customOrder: any;
  setCustomOrder: any;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const configuration = useSelector(
    (state: ApplicationState) => state.socialMediaAutoPost.configuration
  );

  const isDateValid = (post: Post) => {
    let date: any = DateHelper.getDate(post);
    date = date ? moment(date) : null;

    const start = moment()
      .subtract(1, "months")
      .endOf("month")
      .day("Sunday")
      .tz("Europe/Berlin")
      .set({ hour: 10, minute: 5, second: 0 });
    const end = moment()
      .endOf("month")
      .day("Sunday")
      .tz("Europe/Berlin")
      .set({ hour: 10, minute: 5, second: 0 });

    return date && date.isSameOrAfter(start) && date.isSameOrBefore(end);
  };

  const isNews = (post: Post) =>
    Object.keys(post.categories).includes("news") ||
    Object.keys(post.categories).includes("actualites");
  const isStory = (post: Post) =>
    Object.keys(post.categories).includes("stories");
  const isReview = (post: Post) =>
    Object.keys(post.categories).includes("reviews");

  let posts = useSelector((state: ApplicationState) => state.posts.all);
  posts = posts.filter((post) => {
    if (post.status != "publish" && post.status != "future") {
      return false;
    }

    const prio = post.plugins?.social_media_auto_post.newsletter.priority.value;
    if (!prio) {
      return false;
    }

    return isDateValid(post);
  });

  const countTopViewCounts = 50;
  const news = posts.filter(isNews);
  const viewCounts = news.map(
    (post) => post.plugins?.page_views?.countLast30Days || 0
  );
  viewCounts.sort((a, b) => b - a);
  const minViewCount = viewCounts[countTopViewCounts] || 0;

  console.log("=======");
  posts = posts.filter((post) => {
    if (isNews(post)) {
      const viewCount = post.plugins?.page_views?.countLast30Days || 0;
      if (viewCount < minViewCount) {
        console.log(post.id + " is news WITHOUT enough views");
        return false;
      } else {
        console.log(post.id + " is news with enough views");
      }
    } else if (!isStory(post) && !isReview(post)) {
      console.log(post.id + " is NOT story or review");
      return false;
    } else {
      console.log(post.id + " is story or review");
    }

    return true;
  });

  posts.sort((post1, post2) => {
    const customOrder1 = props.customOrder[post1.id];
    const customOrder2 = props.customOrder[post2.id];

    const hasCustomOrder1 = customOrder1 && customOrder1.hasCustomOrder;
    const hasCustomOrder2 = customOrder2 && customOrder2.hasCustomOrder;

    if (!hasCustomOrder1 && hasCustomOrder2) {
      return +1;
    }
    if (!hasCustomOrder2 && hasCustomOrder1) {
      return -1;
    }

    if (hasCustomOrder1 && hasCustomOrder2) {
      if (customOrder1.index != customOrder2.index) {
        return customOrder1.index - customOrder2.index;
      }
    }

    const prio1 =
      post1.plugins?.social_media_auto_post.newsletter.priority.value;
    const prio2 =
      post2.plugins?.social_media_auto_post.newsletter.priority.value;

    if (!prio1 && prio2) {
      return +1;
    }
    if (!prio2 && prio1) {
      return -1;
    }

    if (prio1 && prio2) {
      if (prio1 != prio2) {
        return prio1 - prio2;
      }
    }

    let date1: any = DateHelper.getDate(post1);
    date1 = date1 ? moment(date1) : null;

    let date2: any = DateHelper.getDate(post2);
    date2 = date2 ? moment(date2) : null;

    if (!date1 && date2) {
      return +1;
    }
    if (!date2 && date1) {
      return -1;
    }

    if (date1 && date2) {
      const diff = moment.duration(date2.diff(date1));
      return diff.asSeconds();
    }

    return 0;
  });

  const data: any[] = posts.map((post, index) => {
    const priority =
      post.plugins?.social_media_auto_post.newsletter.priority.value;

    const newsletterConfiguration = configuration.channels.filter(
      (channel: any) => channel.id == "newsletter"
    )[0].validation;

    const titleSmallInfo =
      post.plugins?.social_media_auto_post.newsletter?.title?.value?.small;
    const titleMediumInfo =
      post.plugins?.social_media_auto_post.newsletter?.title?.value?.medium;
    const titleLargeInfo =
      post.plugins?.social_media_auto_post.newsletter?.title?.value?.large;

    const date: any = DateHelper.getDate(post);

    const customOrder = props.customOrder[post.id];

    return {
      id: post.id,
      title: titleSmallInfo || titleMediumInfo || titleLargeInfo,
      titleLength:
        '<div><span style="">' +
        (titleSmallInfo || titleMediumInfo || titleLargeInfo).length +
        "</span></div>",
      author: post.author.displayName,
      status: post.status,
      categories: Object.keys(post.categories),
      drag: { index: index, key: "post-drag-" + post.id },
      postDate: date,
      priority: priority,
      editLink: post.urls.edit,
      viewLink: post.urls.view,
      customOrder: {
        postId: post.id,
        enabled: customOrder && customOrder.hasCustomOrder,
      },
    };
  });

  const columns = [
    {
      name: "editLink",
      label: t("Edit Post"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
          linkRenderer(<LinkIcon />)(value, tableMeta, updateValue),
      },
    },
    {
      name: "viewLink",
      label: t("View Post"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
          linkRenderer(<ExitToAppIcon />)(value, tableMeta, updateValue),
      },
    },
    {
      name: "drag",
      label: t("Drag & Drop"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const id = value.key;
          const index = value.index;

          return (
            <Draggable key={id} draggableId={id} index={index}>
              {(provided: any, snapshot: any) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <PanToolOutlinedIcon />
                </div>
              )}
            </Draggable>
          );
        },
      },
    },
    {
      name: "customOrder",
      label: t("Custom Order"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          const postId = value.postId;
          const enabled = value.enabled;

          const enableButton = (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const newCustomOrder = Object.assign({}, props.customOrder);
                let highestIndex = -1;

                const customOrderKeys = Object.keys(newCustomOrder);
                for (let i = 0; i < customOrderKeys.length; i++) {
                  const postId = customOrderKeys[i];
                  const value = newCustomOrder[postId];

                  if (value && value.hasCustomOrder) {
                    if (value.index > highestIndex) {
                      highestIndex = value.index;
                    }
                  }
                }

                newCustomOrder[postId] = {
                  index: highestIndex + 1,
                  hasCustomOrder: true,
                };
                props.setCustomOrder(newCustomOrder);
              }}
            >
              {t("Enable")}
            </Button>
          );

          const disableButton = (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                const newCustomOrder = Object.assign({}, props.customOrder);
                delete newCustomOrder[postId];
                props.setCustomOrder(newCustomOrder);
              }}
            >
              {t("Disable")}
            </Button>
          );

          return (
            <Box width={100}>{enabled ? disableButton : enableButton}</Box>
          );
        },
      },
    },
    {
      name: "priority",
      label: t("Priority"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={60}>
            {textFieldRenderer({
              readOnly: true,
            })(value, tableMeta, updateValue)}
          </Box>
        ),
      },
    },
    {
      name: "title",
      label: t("Title"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={450}>
            {textFieldRenderer({
              readOnly: true,
            })(value, tableMeta, updateValue)}
          </Box>
        ),
      },
    },
    {
      name: "titleLength",
      label: t("Title Length"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={60}>
            <div dangerouslySetInnerHTML={{ __html: value }}></div>
          </Box>
        ),
      },
    },
    {
      name: "postDate",
      label: t("Post Date"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) =>
          dateTimeRenderer({
            readOnly: true,
          })(value, tableMeta, updateValue),
      },
    },
    {
      name: "author",
      label: t("Author"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={250}>
            {textFieldRenderer({
              readOnly: true,
            })(value, tableMeta, updateValue)}
          </Box>
        ),
      },
    },
    {
      name: "status",
      label: t("Status"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={250}>
            {textFieldRenderer({
              readOnly: true,
            })(value, tableMeta, updateValue)}
          </Box>
        ),
      },
    },
    {
      name: "id",
      label: t("ID"),
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Box width={60}>
            {textFieldRenderer({
              readOnly: true,
            })(value, tableMeta, updateValue)}
          </Box>
        ),
      },
    },
  ];

  const options: any = {
    // "multiselect" | "dropdown" | "checkbox" | "textField" | "custom" | undefined
    filterType: "checkbox",
    filter: false,
    download: false,
    print: false,
    rowsPerPage: 500,
    rowsPerPageOptions: [500],
    selectableRows: "none",
    sort: false,
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const postId = parseInt(result.draggableId.replace("post-drag-", ""));
    const newIndex = result.destination.index;

    const newCustomOrder = Object.assign({}, props.customOrder);
    if (newCustomOrder[postId] && newCustomOrder[postId].hasCustomOrder) {
      const oldIndex = newCustomOrder[postId].index;
      if (oldIndex < newIndex) {
        alert("dragging down is not supported at the moment 😭 😭 😭");
        return;
      }
    }

    const newCustomOrderKeys = Object.keys(newCustomOrder);
    for (let i = 0; i < newCustomOrderKeys.length; i++) {
      const postId = newCustomOrderKeys[i];
      const value = newCustomOrder[postId];

      if (value && value.hasCustomOrder) {
        if (value.index >= newIndex) {
          newCustomOrder[postId] = {
            index: value.index + 1,
            hasCustomOrder: true,
          };
        }
      }
    }

    newCustomOrder[postId] = {
      index: newIndex,
      hasCustomOrder: true,
    };

    props.setCustomOrder(newCustomOrder);
    console.log(newCustomOrder);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: any, snapshot: any) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <div style={{ minWidth: "1900px", margin: "0 auto" }}>
              <MUIDataTable
                title={"Top Articles"}
                data={data}
                columns={columns}
                options={options}
              />
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
