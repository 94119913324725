/**
 * Utility class which offers methods for processing URLs.
 */
export class UrlHelper {
  /**
   * Adds an leading slash to the given path.
   *
   * @example
   * ```ts
   * assert(UrlHelper.addLeadingSlash('api/v1/posts') === '/api/v1/posts');
   * assert(UrlHelper.addLeadingSlash('/api/v1/posts') === '/api/v1/posts');
   * ```
   * @param path the path.
   */
  static addLeadingSlash(path: string): string {
    return path.startsWith("/") ? path : "/" + path;
  }
}
