import Cookies from "universal-cookie";

const cookies = new Cookies();

export interface CookieController {
  loadCookies(): void;
  getCookie(key: string): string;
  setCookie(key: string, value: string): void;
}

export class CookieControllerImpl implements CookieController {
  private getCookiesHostnameMap(key: string) {
    const cookie = cookies.get(key);

    let list: { [key: string]: string } = {};
    if (cookie) {
      try {
        if (typeof cookie === "object" && Object.keys(cookie).length > 0) {
          list = cookie;
        }
      } catch (e) {
        // ignored
      }
    }

    return list;
  }

  private getCookieHostnameMapEntry(key: string): string {
    /*
        const list = this.getCookiesHostnameMap(key);
        const result = list[window.location.hostname];
        return result;
        */
    return sessionStorage.getItem(key) as string;
  }

  private setCookieHostnameMapEntry(key: string, value: string): void {
    /*
        const list = this.getCookiesHostnameMap(key);
        list[window.location.hostname] = value;
        cookies.set(key, list, {path: '/'});
        */
    sessionStorage.setItem(key, value);
  }

  private setCookies(data: any) {
    this.setCookieHostnameMapEntry("ajax_url_by_hostname", data.ajax_url);
    this.setCookieHostnameMapEntry("ajax_nonce_by_hostname", data.ajax_nonce);
    this.setCookieHostnameMapEntry("rest_url_by_hostname", data.rest_url);
    this.setCookieHostnameMapEntry("rest_nonce_by_hostname", data.rest_nonce);
    this.setCookieHostnameMapEntry("rest_token_by_hostname", data.rest_token);
    this.setCookieHostnameMapEntry("cookies_loaded_by_hostname", "1");
  }

  private handleMessage(event: any) {
    const domains = [
      "http://de.devtopia.de",
      "https://de.devtopia.de",

      "http://ch.devtopia.de",
      "https://ch.devtopia.de",

      "http://reisetopia.de",
      "https://reisetopia.de",

      "http://www.reisetopia.de",
      "https://www.reisetopia.de",

      "http://reisetopia.ch",
      "https://reisetopia.ch",

      "http://www.reisetopia.ch",
      "https://www.reisetopia.ch",

      "http://suitespot.fr",
      "https://suitespot.fr",

      "http://www.suitespot.fr",
      "https://www.suitespot.fr",

      "http://suitespot.es",
      "https://suitespot.es",

      "http://www.suitespot.es",
      "https://www.suitespot.es",
    ];

    if (domains.indexOf(event.origin) > -1) {
      try {
        const payload = JSON.parse(event.data);
        if (payload.namespace == "editorialpress") {
          if (payload.message == "MSG_CONFIGURATION") {
            this.setCookies(payload.data);
          }
        }
      } catch (e) {
        // ignored
      }
    }
  }

  private registerEventListener(): void {
    const handleMessage = (event: any) => {
      this.handleMessage(event);
    };

    if (window.addEventListener) {
      window.addEventListener("message", handleMessage, false);
    } else if ((window as any).attachEvent) {
      (window as any).attachEvent("onmessage", handleMessage);
    }

    const receiver = window.parent.window;
    const data = {
      namespace: "editorialpress",
      message: "MSG_READY",
    };
    receiver.postMessage(JSON.stringify(data), "*");
  }

  private loadFromEnvironment(): void {
    const data = {
      ajax_url: process.env.REACT_APP_WP_AJAX_URL as string,
      ajax_nonce: process.env.REACT_APP_WP_AJAX_NONCE as string,
      rest_url: process.env.REACT_APP_WP_REST_URL as string,
      rest_nonce: process.env.REACT_APP_WP_REST_NONCE as string,
      rest_token: process.env.REACT_APP_WP_REST_TOKEN as string,
    };
    this.setCookies(data);
  }

  public loadCookies(): void {
    const isIframe = window.self !== window.top;
    if (isIframe && process.env.REACT_APP_USE_ENV_DATA != "1") {
      this.registerEventListener();
    } else {
      this.loadFromEnvironment();
    }
  }

  public getCookie(key: string): string {
    const result = this.getCookieHostnameMapEntry(key);
    return result;
  }

  public setCookie(key: string, value: string): void {
    this.setCookieHostnameMapEntry(key, value);
  }
}
