import { PostsState } from "./reducer";

export class PostsActionLoad {
  type!: string;
  payload!: PostsState;
}

export class PostsActionUpdate {
  type!: string;
  payload!: PostsState;
}

// define type names for redux
export const typePrefix = "POSTS::";
PostsActionLoad.prototype.type = typePrefix + "LOAD";
PostsActionUpdate.prototype.type = typePrefix + "UPDATE";

// aggregate actions
export type PostsActionType = PostsActionLoad | PostsActionUpdate;

export class PostsActionFactory {
  static load(additionState: PostsState): PostsActionLoad {
    return {
      type: PostsActionLoad.prototype.type,
      payload: additionState,
    };
  }

  static update(additionState: PostsState): PostsActionUpdate {
    return {
      type: PostsActionUpdate.prototype.type,
      payload: additionState,
    };
  }
}
