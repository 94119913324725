import { Post } from "../../../models/post";
import {
  WordpressFetchServiceImpl,
  WordpressFetchService,
} from "../../../providers/wordpress-fetch";
import { ApiResponse } from "../../../models/api-response";
import { Dispatch } from "react";
import { PostsActionFactory } from "./actions";
import { CookieController, CookieControllerImpl } from "../cookies/controller";
import { Schema } from "@wordpress/api-fetch";
import {
  EditScreenPostMutableState,
  EditScreenPostImmutableState,
} from "../edit-screen/reducer";
import { DateHelper } from "../../../helpers/date-helper";

export interface PostController {
  updateQuality(postId: any, quality: any): Promise<Post>;
  updateRescheduleData(postId: any, frequency: string): Promise<Post>;
  loadState(dispatch: Dispatch<any>, initialLoad: boolean): Promise<void>;
  getExtendedStatus(post: Post): string;
  getPost(baseUrl: string, postId: number): Promise<Post>;
  savePost(
    modifiedState: EditScreenPostMutableState,
    sharedState: EditScreenPostImmutableState,
    authors: Schema.User[],
    categories: Schema.Category[]
  ): Promise<Post>;
  createPost(
    modifiedState: EditScreenPostMutableState,
    authors: Schema.User[],
    categories: Schema.Category[]
  ): Promise<Post>;
  updateScaDate(postId: number, date: Date): Promise<Post>;
}

export class PostControllerImpl implements PostController {
  private async getPublishedPosts(baseUrl: string): Promise<Post[]> {
    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    const getPosts__ = async (offset: number): Promise<Post[]> => {
      try {
        const path =
          "/editorialpress/v1/posts?offset=" +
          offset +
          "&apikey=3125FE942564542A1A9B1F574DB0261B97CC03B2558158CBBFFA2AA301FFEECC";

        const result = await wordpressFetchService.doRequest<
          ApiResponse<Post[]>
        >("GET", baseUrl, path);
        if (result.success && result.data !== null) {
          return result.data;
        } else {
          const message = "Unexpected error occurred!";
          console.log(message);
          throw new Error();
        }
      } catch (err) {
        const message = "Unexpected error occurred!";
        console.log(message);
        throw err;
      }
    };

    let result: Post[] = [];
    let offset = 0;

    while (true) {
      const promise1 = getPosts__(offset);
      const promise2 = getPosts__(offset + 1000);
      const promise3 = getPosts__(offset + 2000);

      const result1 = await promise1;
      const result2 = await promise2;
      const result3 = await promise3;
      result = result.concat(result1, result2, result3);

      if (result3.length <= 0) {
        break;
      }

      offset = offset + 3000;
    }

    return result;
  }

  private async getUpdatedPosts(baseUrl: string): Promise<Post[]> {
    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    const getPosts__ = async (): Promise<Post[]> => {
      try {
        const path =
          "/editorialpress/v1/updated-posts" +
          "?apikey=3125FE942564542A1A9B1F574DB0261B97CC03B2558158CBBFFA2AA301FFEECC";

        const result = await wordpressFetchService.doRequest<
          ApiResponse<Post[]>
        >("GET", baseUrl, path);
        if (result.success && result.data !== null) {
          return result.data;
        } else {
          const message = "Unexpected error occurred!";
          console.log(message);
          throw new Error();
        }
      } catch (err) {
        const message = "Unexpected error occurred!";
        console.log(message);
        throw err;
      }
    };

    let result: Post[] = await getPosts__();
    return result;
  }

  public async getPost(baseUrl: string, postId: number): Promise<Post> {
    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    try {
      const path =
        "/editorialpress/v1/posts/" +
        postId +
        "?apikey=3125FE942564542A1A9B1F574DB0261B97CC03B2558158CBBFFA2AA301FFEECC";

      const result = await wordpressFetchService.doRequest<ApiResponse<Post>>(
        "GET",
        baseUrl,
        path
      );
      if (result.success && result.data !== null) {
        return result.data;
      } else {
        const message = "Unexpected error occurred!";
        console.log(message);
        throw new Error();
      }
    } catch (err) {
      const message = "Unexpected error occurred!";
      console.log(message);
      throw err;
    }
  }

  public getExtendedStatus(post: Post): string {
    const date = DateHelper.getDate(post);
    const status = post.status;

    if (status == "future") {
      return "scheduled";
    } else if (status == "draft") {
      return "draft";
    } else if (status == "publish") {
      const now = new Date();
      if (date < now) {
        return "published";
      } else {
        return "rescheduled";
      }
    } else if (status == "pending") {
      return "pending";
    } else if (status == "private") {
      return "private";
    } else {
      return status;
    }
  }

  public async loadState(
    dispatch: Dispatch<any>,
    initialLoad: boolean
  ): Promise<void> {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");
    // Zeile 125 wirft manchmal einen Fehler, da cookieController.getCookie('rest_url_by_hostname') undefined!!!

    if (initialLoad) {
      const posts = await this.getPublishedPosts(baseUrl);
      const additionalState = {
        all: posts,
      };
      const action = PostsActionFactory.load(additionalState);
      dispatch(action);
    } else {
      const posts = await this.getUpdatedPosts(baseUrl);
      const additionalState = {
        all: posts,
      };
      const action = PostsActionFactory.update(additionalState);
      dispatch(action);
    }
  }

  private getAuthorIdBySlug(slug: string, authors: Schema.User[]): number {
    for (let i = 0; i < authors.length; i++) {
      const author = authors[i];
      if (author.slug == slug) {
        return author.id;
      }
    }
    throw new Error("Unknown author with slug '" + slug + "'");
  }

  private getCategoryIdsBySlugs(
    slugs: string[],
    categories: Schema.Category[]
  ): number[] {
    return slugs.map((slug) => {
      for (let i = 0; i < categories.length; i++) {
        const category = categories[i];
        if (category.slug == slug) {
          return category.id;
        }
      }
      throw new Error("Unknown category with slug '" + slug + "'");
    });
  }

  public async createPost(
    modifiedState: EditScreenPostMutableState,
    authors: Schema.User[],
    categories: Schema.Category[]
  ): Promise<Post> {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");
    const restToken = cookieController.getCookie("rest_token_by_hostname");

    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    try {
      const authorId = this.getAuthorIdBySlug(modifiedState.author, authors);
      const categoryIds = this.getCategoryIdsBySlugs(
        modifiedState.categories,
        categories
      );

      const data = {
        title: modifiedState.title,
        author: authorId,
        categories: categoryIds,
        status: modifiedState.status,
        date: modifiedState.date,
        meta_fields: {
          ep_without_time: !modifiedState.withTime,
        },
      };

      const path = "/wp/v2/posts";
      const post = await wordpressFetchService.doRequest<any>(
        "POST",
        baseUrl,
        path,
        data,
        {
          Authorization: "Bearer " + restToken,
        }
      );

      const result = await this.getPost(baseUrl, post.id);
      return result;
    } catch (err) {
      const message = "Unexpected error occurred!";
      console.log(message);
      throw err;
    }
  }

  public async updateScaDate(postId: number, date: Date): Promise<Post> {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");
    const restToken = cookieController.getCookie("rest_token_by_hostname");

    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    try {
      const data = {
        plugins: {
          scheduled_content_actions: {
            post_date: date,
          },
        },
      };

      const path =
        "/editorialpress/v1/posts/" +
        postId +
        "?apikey=3125FE942564542A1A9B1F574DB0261B97CC03B2558158CBBFFA2AA301FFEECC";
      await wordpressFetchService.doRequest<Schema.Post>(
        "POST",
        baseUrl,
        path,
        data,
        {
          Authorization: "Bearer " + restToken,
        }
      );

      const result = await this.getPost(baseUrl, postId);
      return result;
    } catch (err) {
      const message = "Unexpected error occurred!";
      console.log(message);
      throw err;
    }
  }

  public async updateQuality(postId: any, quality: any): Promise<Post> {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");
    const restToken = cookieController.getCookie("rest_token_by_hostname");

    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    try {
      const path =
        "/editorialpress/v1/posts/" +
        postId +
        "/rating?apikey=3125FE942564542A1A9B1F574DB0261B97CC03B2558158CBBFFA2AA301FFEECC&rating=" +
        quality;
      await wordpressFetchService.doRequest<Schema.Post>(
        "POST",
        baseUrl,
        path,
        {},
        {
          Authorization: "Bearer " + restToken,
        }
      );

      const result = await this.getPost(baseUrl, postId);
      return result;
    } catch (err) {
      const message = "Unexpected error occurred!";
      console.log(message);
      throw err;
    }
  }

  public async updateRescheduleData(
    postId: any,
    frequency: string
  ): Promise<Post> {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");
    const restToken = cookieController.getCookie("rest_token_by_hostname");

    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    try {
      const path =
        "/editorialpress/v1/posts/" +
        postId +
        "/reschedule-frequency?apikey=3125FE942564542A1A9B1F574DB0261B97CC03B2558158CBBFFA2AA301FFEECC&reschedule_frequency=" +
        frequency;
      await wordpressFetchService.doRequest<Schema.Post>(
        "POST",
        baseUrl,
        path,
        {},
        {
          Authorization: "Bearer " + restToken,
        }
      );

      const result = await this.getPost(baseUrl, postId);
      return result;
    } catch (err) {
      const message = "Unexpected error occurred!";
      console.log(message);
      throw err;
    }
  }

  public async savePost(
    modifiedState: EditScreenPostMutableState,
    sharedState: EditScreenPostImmutableState,
    authors: Schema.User[],
    categories: Schema.Category[]
  ): Promise<Post> {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");
    const restToken = cookieController.getCookie("rest_token_by_hostname");

    const wordpressFetchService: WordpressFetchService = new WordpressFetchServiceImpl();

    try {
      const postId = sharedState.id;
      const authorId = this.getAuthorIdBySlug(modifiedState.author, authors);
      const categoryIds = this.getCategoryIdsBySlugs(
        modifiedState.categories,
        categories
      );

      const data = {
        title: modifiedState.title,
        author: authorId,
        categories: categoryIds,
        status: modifiedState.status,
        date: modifiedState.date,
        meta_fields: {
          ep_without_time: !modifiedState.withTime,
        },
      };

      const path = "/wp/v2/posts/" + postId;
      await wordpressFetchService.doRequest<Schema.Post>(
        "POST",
        baseUrl,
        path,
        data,
        {
          Authorization: "Bearer " + restToken,
        }
      );

      const result = await this.getPost(baseUrl, postId);
      return result;
    } catch (err) {
      const message = "Unexpected error occurred!";
      console.log(message);
      throw err;
    }
  }
}
