import { CategoriesState } from "./reducer";

export class CategoriesActionUpdate {
  type!: string;
  payload!: CategoriesState;
}

// define type names for redux
export const typePrefix = "CATEGORIES::";
CategoriesActionUpdate.prototype.type = typePrefix + "UPDATE";

// aggregate actions
export type CategoriesActionType = CategoriesActionUpdate;

export class CategoriesActionFactory {
  static update(additionState: CategoriesState): CategoriesActionUpdate {
    return {
      type: CategoriesActionUpdate.prototype.type,
      payload: additionState,
    };
  }
}
