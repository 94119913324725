import React, { useEffect, useState } from "react";
import { TextField, Box, MenuItem, Select, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../components/application/storage/store";
import { useTranslation } from "react-i18next";
import {
  SocialMediaAutoPostController,
  SocialMediaAutoPostControllerImpl,
} from "../components/application/social-media-auto-post/controller";
import { SocialMediaAutoPostActionFactory } from "../components/application/social-media-auto-post/actions";
import { NewsletterListDailyComponent } from "../components/presentation/newsletter-list-daily/component";
import {
  CookieController,
  CookieControllerImpl,
} from "../components/application/cookies/controller";
import {
  ReisetopiaNewsletterController,
  ReisetopiaNewsletterControllerImpl,
} from "../components/application/reisetopia-newsletter/controller";

export function DailyNewsletterComponent() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const configuration = useSelector(
    (state: ApplicationState) => state.socialMediaAutoPost.configuration
  );
  const title = useSelector(
    (state: ApplicationState) =>
      state.socialMediaAutoPost.newsletter.daily.subject
  );

  const [titleChanged, setTitleChanged] = useState(null as string | null);
  const handleTitleChange = (event: React.ChangeEvent<any>) => {
    setTitleChanged(event.target.value);
  };
  const titleCurrent = titleChanged === null ? title : titleChanged;

  const handleSave = (callback: any) => {
    const data = {
      subject: titleCurrent,
    };

    const socialMediaAutoPostController: SocialMediaAutoPostController = new SocialMediaAutoPostControllerImpl();
    const promise = socialMediaAutoPostController.postNewsletterDailyData(data);
    promise
      .then((data) => {
        if (callback) {
          callback();
        }
      })
      .catch(() => {
        alert("Error while saving subject! Please try again!");
      });

    const additionalState = {
      configuration: configuration,
      newsletter: {
        daily: {
          subject: data.subject,
        },
      },
    };

    const action = SocialMediaAutoPostActionFactory.update(additionalState);
    dispatch(action);
  };

  const handleReset = () => {
    const data = {
      subject: "",
    };

    const socialMediaAutoPostController: SocialMediaAutoPostController = new SocialMediaAutoPostControllerImpl();
    const promise = socialMediaAutoPostController.postNewsletterDailyData(data);
    promise
      .then((data) => {
        // ...
      })
      .catch(() => {
        alert("Error while resetting subject! Please try again!");
      });

    const additionalState = {
      configuration: configuration,
      newsletter: {
        daily: {
          subject: data.subject,
        },
      },
    };

    const action = SocialMediaAutoPostActionFactory.update(additionalState);
    dispatch(action);
  };

  const handleRefresh = () => {
    const result = window.confirm(
      t(
        "Your local changes (in case there are any) will be discarded while refreshing this page. Are you sure you want to refresh the page?"
      )
    );
    if (result != true) {
      return;
    }

    window.location.reload();
  };

  const [scheduledCampaigns, setScheduledCampaigns] = useState([]);
  const [buttonsEnabled, setButtonsEnabled] = useState(true);

  const handleRefreshCampaigns = () => {
    const newsletterController: ReisetopiaNewsletterController = new ReisetopiaNewsletterControllerImpl();
    const promise = newsletterController.getScheduledNewsletters();
    promise
      .then((data) => {
        if (data.campaigns && data.campaigns.length > 0) {
          setScheduledCampaigns(data.campaigns);
        } else {
          setScheduledCampaigns([]);
        }
      })
      .catch(() => {
        alert("Error while getting scheduled newsletters! Please try again!");
      });

    return promise;
  };

  const handleRefreshCampaignsSafe = () => {
    if (!buttonsEnabled) {
      return Promise.resolve(null);
    }
    setButtonsEnabled(false);

    const promise = handleRefreshCampaigns();
    promise.finally(() => {
      setButtonsEnabled(true);
    });
  };

  const handlePreview = () => {
    const cookieController: CookieController = new CookieControllerImpl();
    const baseUrl = cookieController
      .getCookie("rest_url_by_hostname")
      .replace("/wp-json/", "");

    window.open(
      baseUrl +
        "/wp-json/editorialpress/v1/social-media-auto-post/newsletter/daily/preview"
    );
  };

  const handleSchedule = () => {
    if (!buttonsEnabled) {
      return Promise.resolve(null);
    }
    setButtonsEnabled(false);

    const newsletterController: ReisetopiaNewsletterController = new ReisetopiaNewsletterControllerImpl();
    const promise = newsletterController.postNewsletterSchedule();
    promise
      .then((data) => {
        const refreshPromise = handleRefreshCampaigns();
        refreshPromise.then((data) => {
          alert("Newsletter scheduled!");
        });
      })
      .catch(() => {
        alert("Error while scheduling newsletter! Please try again!");
      })
      .finally(() => {
        setButtonsEnabled(true);
      });
  };

  const handleDeleteCampaign = (id: string) => {
    if (!buttonsEnabled) {
      return Promise.resolve(null);
    }
    setButtonsEnabled(false);

    const newsletterController: ReisetopiaNewsletterController = new ReisetopiaNewsletterControllerImpl();
    const promise = newsletterController.deleteScheduledNewsletter(id);
    promise
      .then((data) => {
        const refreshPromise = handleRefreshCampaigns();
        refreshPromise.then((data) => {
          alert("Newsletter deleted!");
        });
      })
      .catch(() => {
        alert("Error while deleting newsletter! Please try again!");
      })
      .finally(() => {
        setButtonsEnabled(true);
      });
  };

  useEffect(() => {
    handleRefreshCampaignsSafe();
  }, []);

  const toolbar = () => {
    return (
      <Box m={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleSave(null);
          }}
          style={{ fontWeight: 600, width: "160px" }}
        >
          {t("Save")}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleRefresh}
          style={{ fontWeight: 600, width: "160px", marginLeft: "20px" }}
        >
          {t("Refresh")}
        </Button>

        <Button
          variant="contained"
          color="default"
          onClick={handleReset}
          style={{
            fontWeight: 600,
            width: "160px",
            marginLeft: "20px",
            background: "#CA0B00",
            color: "white",
          }}
        >
          {t("Reset")}
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Box m={4} />

      <Box
        m={4}
        style={{
          background: "#EBE4E0",
          width: "600px",
          padding: "30px",
          margin: "0 auto",
        }}
      >
        <Box style={{ textAlign: "left", width: "500px", margin: "0 auto" }}>
          <b>Current subject</b>: {title}
        </Box>

        <Box m={3}>
          <TextField
            id="standard-basic"
            label={t("Newsletter Subject")}
            value={titleCurrent}
            onChange={handleTitleChange}
            error={!titleCurrent}
            style={{ width: "500px" }}
          />
        </Box>

        {toolbar()}
      </Box>

      <Box
        m={4}
        style={{
          background: "#EBE4E0",
          width: "600px",
          padding: "30px",
          margin: "20px auto",
        }}
      >
        <Box m={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleSave(handlePreview);
            }}
            style={{ fontWeight: 600, width: "160px" }}
            disabled={!buttonsEnabled}
          >
            {t("Save & Preview")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleSave(handleSchedule);
            }}
            style={{ fontWeight: 600, marginLeft: "20px" }}
            disabled={!buttonsEnabled}
          >
            {t("Save & Schedule")}
          </Button>
        </Box>

        <Box m={3}>
          <hr />
        </Box>

        <Box style={{ textAlign: "left", width: "500px", margin: "0 auto" }}>
          <b>Newsletters scheduled for today:</b>

          <ul>
            {scheduledCampaigns.length > 0 ? (
              scheduledCampaigns.map((campaign: any) => {
                return (
                  <li>
                    <Box m={1}>
                      <b>{t("Name")}</b>: {campaign.name}
                    </Box>
                    <Box m={1}>
                      <b>{t("Date")}</b>: {campaign.schedule_date}
                    </Box>
                    <Box m={1}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          handleDeleteCampaign(campaign.id);
                        }}
                        style={
                          buttonsEnabled
                            ? { fontWeight: 600 }
                            : {
                                fontWeight: 600,
                                background: "#CA0B00",
                                color: "white",
                              }
                        }
                        disabled={!buttonsEnabled}
                      >
                        {t("Delete")}
                      </Button>
                    </Box>
                  </li>
                );
              })
            ) : (
              <span>No newsletter scheduled yet.</span>
            )}
          </ul>
        </Box>

        <Box m={3}>
          <hr />
        </Box>

        <Box m={1}>
          The list above doesn't load or refresh automatically.
          <br />
          Press "Refresh scheduled campaigns" to load or refresh the list.
        </Box>

        <Box m={1}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleRefreshCampaignsSafe}
            style={{ fontWeight: 600 }}
            disabled={!buttonsEnabled}
          >
            {t("Refresh scheduled campaigns")}
          </Button>
        </Box>
      </Box>

      <Box m={4} />

      <NewsletterListDailyComponent />
    </>
  );
}
