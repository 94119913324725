import React from "react";

import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
  TextField,
  Divider,
  Input,
  ListItemText,
  Checkbox,
  Switch,
  FormControlLabel,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextFieldProps,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../application/storage/store";

import { useTranslation } from "react-i18next";
import { EditScreenActionFactory } from "../../application/edit-screen/actions";
import { useDispatch } from "react-redux";
import {
  PostController,
  PostControllerImpl,
} from "../../application/posts/controller";
import lodash from "lodash";
import {
  createInitialPostMutableState,
  createInitialPostImmutableState,
  createInitialDialogState,
  createInitialPostMutableStateFromPost,
  createInitialPostImmutableStateFromPost,
} from "../../application/edit-screen/reducer";
import { Post } from "../../../models/post";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    maxWidth: "300px",
    width: "90%",
  },

  formContainer: {
    //padding: theme.spacing(4),
  },
}));

function sortEntries(unsorted: {
  [key: string]: string;
}): { [key: string]: string } {
  const swappedUnsorted: { [key: string]: string } = {};
  for (var key in unsorted) {
    swappedUnsorted[unsorted[key]] = key;
  }

  const swappedSorted: { [key: string]: string } = {};
  Object.keys(swappedUnsorted)
    .sort()
    .forEach(function (key) {
      swappedSorted[key] = swappedUnsorted[key];
    });

  const sorted: { [key: string]: string } = {};
  for (var key in swappedSorted) {
    sorted[swappedSorted[key]] = key;
  }

  return sorted;
}

function CreateDraftDialogComponent() {
  moment.locale("en", {
    week: {
      doy: 1,
      dow: 1,
    },
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const initialState = useSelector(
    (state: ApplicationState) => state.editScreen.initialState
  );
  const modifiedState = useSelector(
    (state: ApplicationState) => state.editScreen.modifiedState
  );
  const immutableState = useSelector(
    (state: ApplicationState) => state.editScreen.immutableState
  );
  const dialogState = useSelector(
    (state: ApplicationState) => state.editScreen.dialogState
  );

  // -------
  function isExistingPost() {
    return immutableState.id != 0;
  }

  function updateState(updates: any) {
    const additionalState = {
      initialState: updates.initialState ? updates.initialState : {},
      modifiedState: updates.modifiedState ? updates.modifiedState : {},
      immutableState: updates.immutableState ? updates.immutableState : {},
      dialogState: updates.dialogState ? updates.dialogState : {},
    };

    const state = {
      initialState: Object.assign(
        {},
        initialState,
        additionalState.initialState
      ),
      modifiedState: Object.assign(
        {},
        modifiedState,
        additionalState.modifiedState
      ),
      immutableState: Object.assign(
        {},
        immutableState,
        additionalState.immutableState
      ),
      dialogState: Object.assign({}, dialogState, additionalState.dialogState),
    };

    const formIsInvalid =
      !state.modifiedState.title ||
      !state.modifiedState.author ||
      state.modifiedState.categories.length < 1 ||
      !state.modifiedState.status ||
      !state.modifiedState.date;

    let saveButtonEnabled = false;
    if (
      !formIsInvalid &&
      !lodash.isEqual(state.initialState, state.modifiedState)
    ) {
      saveButtonEnabled = true;
    }

    const dialogUpdates = {
      saveButtonStatus: "save",
      saveButtonEnabled: saveButtonEnabled,
    };
    state.dialogState = Object.assign(
      state.dialogState,
      dialogUpdates,
      additionalState.dialogState
    );

    const action = EditScreenActionFactory.update(state);
    dispatch(action);
  }

  // -------

  const availableStatuses = {
    publish: t("Published"),
    future: t("Scheduled"),
    pending: t("Pending"),
    draft: t("Draft"),
    private: t("Private"),
  };

  const allAuthors = useSelector(
    (state: ApplicationState) => state.users.authors
  );
  let availableAuthors: { [key: string]: string } = {};
  allAuthors.forEach((author) => {
    availableAuthors[author.slug] = author.name;
  });
  availableAuthors = sortEntries(availableAuthors);

  const allCategories = useSelector(
    (state: ApplicationState) => state.categories.all
  );
  let availableCategories: { [key: string]: string } = {};
  allCategories.forEach((category) => {
    const parentId = category.parent;
    let parent = null;

    if (parentId) {
      allCategories.forEach((category) => {
        if (parentId == category.id) {
          parent = category.name;
        }
      });
    }

    let name = category.name;
    if (parent) {
      name = parent + " ➜ " + name;
    }
    availableCategories[category.slug] = name;
  });
  availableCategories = sortEntries(availableCategories);

  // -----------
  const handleTitleChange = (event: React.ChangeEvent<any>) => {
    const updates = { modifiedState: { title: event.target.value } };
    updateState(updates);
  };

  const handleAuthorChange = (event: React.ChangeEvent<any>) => {
    const updates = { modifiedState: { author: event.target.value } };
    updateState(updates);
  };

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (!date) {
      return;
    }

    const updates = { modifiedState: { date: date.toDate() } };
    updateState(updates);
  };

  const toggleWithTimeChange = () => {
    const updates = { modifiedState: { withTime: !modifiedState.withTime } };
    updateState(updates);
  };

  const handleStatusChange = (event: React.ChangeEvent<any>) => {
    const updates = { modifiedState: { status: event.target.value } };
    updateState(updates);
  };

  const handleCategoriesChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const updates = {
      modifiedState: { categories: event.target.value as string[] },
    };
    updateState(updates);
  };

  const handleClickOpen = () => {
    const state = {
      initialState: createInitialPostMutableState(),
      modifiedState: createInitialPostMutableState(),
      immutableState: createInitialPostImmutableState(),
      dialogState: createInitialDialogState(true, false),
    };

    const action = EditScreenActionFactory.update(state);
    dispatch(action);
  };

  // -------

  const handleClose = () => {
    var confirmed = true;
    if (!lodash.isEqual(initialState, modifiedState)) {
      confirmed = window.confirm(
        t(
          "You have unsaved changes which will be discarded if you close the dialog!\n\nIf you are sure, you want to close the dialog press 'Ok'!"
        )
      );
    }

    if (!confirmed) {
      return;
    }

    // close the dialog
    const updates = { dialogState: { isOpen: false } };
    updateState(updates);
  };

  const handleSave = () => {
    const updates = {
      dialogState: { saveButtonStatus: "saving", saveButtonEnabled: false },
    };
    updateState(updates);

    const postController: PostController = new PostControllerImpl();

    let promise = null;
    if (isExistingPost()) {
      promise = postController.savePost(
        modifiedState,
        immutableState,
        allAuthors,
        allCategories
      );
    } else {
      promise = postController.createPost(
        modifiedState,
        allAuthors,
        allCategories
      );
    }

    if (!promise) {
      return;
    }

    promise
      .then((post: Post) => {
        const updates = {
          initialState: createInitialPostMutableStateFromPost(post),
          modifiedState: createInitialPostMutableStateFromPost(post),
          immutableState: createInitialPostImmutableStateFromPost(post),
          dialogState: { saveButtonStatus: "saved", saveButtonEnabled: false },
        };
        updateState(updates);

        // refresh calendar
        postController.loadState(dispatch, false);
      })
      .catch((err) => {
        const updates = {
          dialogState: { saveButtonStatus: "error", saveButtonEnabled: false },
        };
        updateState(updates);
      });
  };

  const handleView = () => {
    var win = window.open(immutableState.viewUrl, "_blank");
    if (win) {
      win.focus();
    }
  };

  const handleEdit = () => {
    var win = window.open(immutableState.editUrl, "_blank");
    if (win) {
      win.focus();
    }
  };

  // ---------
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 400,
        width: 250,
      },
    },
  };

  let saveButtonText = "";
  if (dialogState.saveButtonStatus == "save") {
    saveButtonText = t("Save");
  } else if (dialogState.saveButtonStatus == "saving") {
    saveButtonText = t("Saving...");
  } else if (dialogState.saveButtonStatus == "saved") {
    saveButtonText = t("Saved!");
  } else if (dialogState.saveButtonStatus == "error") {
    saveButtonText = t("Error!");
  }

  // text field with disabled input
  const customTextField = function (props: TextFieldProps) {
    return (
      <TextField
        {...props}
        onChange={(event) => {
          event.target.value = "";
        }}
      />
    );
  };

  const form = (
    <form>
      <Divider />

      <Grid container justify="space-around" className={classes.formContainer}>
        &nbsp;
        <TextField
          id="standard-basic"
          label={t("Title")}
          className={classes.formControl}
          value={modifiedState.title}
          onChange={handleTitleChange}
          error={!modifiedState.title}
        />
        <br />
        {/* author */}
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">{t("Author")}</InputLabel>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={modifiedState.author}
            onChange={handleAuthorChange}
            MenuProps={MenuProps}
            error={!modifiedState.author}
          >
            {Object.entries(availableAuthors).map(([key, value]) => {
              return <MenuItem value={key}>{value}</MenuItem>;
            })}
          </Select>
        </FormControl>
        {/* category */}
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-mutiple-checkbox-label">
            {t("Categories")}
          </InputLabel>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={modifiedState.categories}
            onChange={handleCategoriesChange}
            input={<Input />}
            renderValue={(selected) =>
              (selected as string[])
                .map((key) => {
                  return availableCategories[key];
                })
                .join(", ")
            }
            MenuProps={MenuProps}
            error={modifiedState.categories.length < 1}
          >
            {Object.entries(availableCategories).map(([key, value]) => {
              return (
                <MenuItem key={key} value={key}>
                  <Checkbox
                    checked={modifiedState.categories.indexOf(key) > -1}
                  />
                  <ListItemText primary={value} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Divider />

      <Grid container justify="space-around" className={classes.formContainer}>
        {/* status */}
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">{t("Status")}</InputLabel>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={modifiedState.status}
            onChange={handleStatusChange}
            error={!modifiedState.status}
          >
            {Object.entries(availableStatuses).map(([key, value]) => {
              return <MenuItem value={key}>{value}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid container justify="space-around" className={classes.formContainer}>
        {/* date and time*/}
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={"en"}
        >
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label={t("Date")}
            format="YYYY/MM/DD"
            value={modifiedState.date}
            onChange={handleDateChange}
            TextFieldComponent={customTextField}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            error={!modifiedState.date}
          />

          <KeyboardTimePicker
            margin="normal"
            id="time-picker"
            label={t("Time")}
            value={modifiedState.date}
            onChange={handleDateChange}
            TextFieldComponent={customTextField}
            KeyboardButtonProps={{
              "aria-label": "change time",
            }}
            style={modifiedState.withTime ? {} : { display: "none" }}
            error={!modifiedState.date}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid container justify="flex-end" className={classes.formContainer}>
        <FormControlLabel
          control={
            <Switch
              checked={modifiedState.withTime}
              onChange={toggleWithTimeChange}
              color="primary"
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
          label={t("Schedule Time?")}
          style={{ marginRight: "30px" }}
        />
      </Grid>

      <Divider />
    </form>
  );

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        style={{ fontWeight: 600 }}
      >
        {t("Create Draft")}
      </Button>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={dialogState.isOpen}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{t("Edit Post")}</DialogTitle>

        <DialogContent>{form}</DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            {t("Close")}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={handleView}
            disabled={!isExistingPost()}
          >
            {t("View Post")}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={handleEdit}
            disabled={!isExistingPost()}
          >
            {t("Edit Post")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={!dialogState.saveButtonEnabled}
          >
            {saveButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default CreateDraftDialogComponent;
